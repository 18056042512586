import { zodResolver } from '@hookform/resolvers/zod';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { z } from 'zod';
import {
    InstitutionOrganisation,
    institutionsOrganisationsQuery,
} from '../../../../api/organisations';
import { queryClient } from '../../../../api/queryClient';
import {
    createWorkspaceMembershipMutation,
    updateWorkspaceMembershipMutation,
    workspaceMembershipsQuery,
} from '../../../../api/workspaceMembership';
import InfoTooltip from '../../../../components/InfoTooltip';
import DragAndDropFileField from '../../../../components/fields/DragAndDropFileField';
import TextFieldArray from '../../../../components/fields/TextFieldArray';
import {
    characterLimit,
    noSpecialChars,
    optionalFile,
    optionalString,
    requiredString,
} from '../../../../validationRules';
import { useWorkspace } from '../../workspaceContext';

const validationRules = z.object({
    customer_number: optionalString.and(characterLimit(30)).and(noSpecialChars),
    comments: optionalString.and(characterLimit(800)).and(noSpecialChars),
    letter_file: optionalFile,
    known_holdings: z.array(requiredString.and(characterLimit(40)).and(noSpecialChars)),
});

type CreateParticipantFields = z.infer<typeof validationRules>;

interface AddParticipantDialogProps extends DialogProps {
    onClose: () => void;
}

const AddParticipantDialog = ({ onClose, ...dialogProps }: AddParticipantDialogProps) => {
    const [page, setPage] = useState<1 | 2>(1);
    const [selectedOrganisation, setSelectedOrganisation] = useState<
        InstitutionOrganisation | undefined | null
    >(undefined);
    const workspace = useWorkspace();

    const organisations = useQuery(institutionsOrganisationsQuery({ active: true }));
    const participants = useQuery(workspaceMembershipsQuery({ workspace: workspace.id }));

    // Filter duplicate participants when adding a new invitation
    const filteredOrganisations = () => {
        if (organisations.data === undefined) return [];
        if (participants.data === undefined) return [];

        // Allow participants to be re-invited if they have declined
        const participantIds = participants.data
            .filter((p) => p.status !== 'declined')
            .map((p) => p.organisation);

        return organisations.data.results
            .filter((o) => !participantIds.includes(o.id))
            .sort((a, b) => {
                const nameA = a.external_display_name || a.name;
                const nameB = b.external_display_name || b.name;
                return nameA.localeCompare(nameB);
            });
    };

    const createMembership = useMutation(createWorkspaceMembershipMutation());
    const updateMembership = useMutation(updateWorkspaceMembershipMutation());

    const handleSubmit = async (values: CreateParticipantFields) => {
        // If organisation is already a workspace participant, do not create a new one
        const participantId = participants.data?.find(
            (p) => p.organisation === selectedOrganisation?.id
        )?.id;

        if (participantId) {
            await updateMembership
                .mutateAsync({
                    id: participantId,
                    customer_number: values.customer_number,
                    comments: values.comments,
                    status: 'invited',
                    known_holdings: values.known_holdings,
                    letter_file: values.letter_file,
                })
                .then(() => {
                    enqueueSnackbar('Participant updated.', { variant: 'success' });
                });
        } else {
            await createMembership
                .mutateAsync({
                    workspace: workspace.id,
                    organisation: selectedOrganisation?.id!,
                    known_holdings: values.known_holdings,
                    customer_number: values.customer_number || '',
                    comments: values.comments || '',
                    letter_file: values.letter_file,
                })
                .then(() => {
                    enqueueSnackbar('Participant invited.', { variant: 'success' });
                });
        }
        queryClient.invalidateQueries(workspaceMembershipsQuery({ workspace: workspace.id }));
        onClose();
    };

    return (
        <Dialog maxWidth="md" fullWidth onClose={onClose} scroll="body" {...dialogProps}>
            {page === 1 && (
                <Box>
                    <DialogTitle>Invite Participant</DialogTitle>

                    <DialogContent>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (selectedOrganisation) {
                                    setPage(2);
                                }
                            }}
                        >
                            <Autocomplete
                                value={selectedOrganisation}
                                onChange={(_, org) => {
                                    setSelectedOrganisation(org);
                                }}
                                options={filteredOrganisations()}
                                getOptionLabel={(org) => org.external_display_name || org.name}
                                isOptionEqualToValue={(org, value) => org.id === value.id}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Select organisation" required />
                                )}
                            />
                            <DialogActions>
                                <Button color="inherit" onClick={onClose}>
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!selectedOrganisation}
                                >
                                    Next
                                </Button>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Box>
            )}

            {page === 2 && selectedOrganisation && (
                <ParticipantDetailsForm
                    onSubmit={handleSubmit}
                    onBack={() => setPage(1)}
                    selectedOrganisation={selectedOrganisation}
                />
            )}
        </Dialog>
    );
};

interface ParticipantDetailsFormProps {
    onSubmit: (values: CreateParticipantFields) => Promise<void>;
    onBack: () => void;
    selectedOrganisation: InstitutionOrganisation;
}

const ParticipantDetailsForm = ({
    onSubmit,
    onBack,
    selectedOrganisation,
}: ParticipantDetailsFormProps) => {
    const [showLetter, setShowLetter] = useState<'yes' | 'no'>('no');

    const formMethods = useForm<CreateParticipantFields>({
        mode: 'onChange',
        defaultValues: {
            customer_number: '',
            letter_file: undefined,
            comments: '',
            known_holdings: [],
        },
        resolver: zodResolver(validationRules),
    });

    // Prevent accidental form submission via Enter key at request of the client.
    // This handler should only be applied to form elements that don't rely on
    // Enter for their primary function (e.g., single-line text inputs, but not
    // textareas or buttons)
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    // if (selectedOrganisation.industry_code === '29') {
    //     // TODO don't use this form if the participant has not elected to add super details
    //     return <InviteSuperannuationForm />;
    // }

    return (
        <FormContainer formContext={formMethods} onSuccess={onSubmit}>
            <DialogTitle>{`Invite ${selectedOrganisation?.name}`}</DialogTitle>

            <DialogContent>
                <Stack direction="column" gap={4}>
                    <TextFieldElement
                        onKeyDown={handleKeyDown}
                        name="customer_number"
                        label={
                            <Stack direction="row" gap={1}>
                                <FormLabel id="customer-identifier-label">
                                    Customer identifier
                                </FormLabel>
                            </Stack>
                        }
                        helperText={
                            selectedOrganisation?.industry_code === '22'
                                ? "Add any known SRN, HIN or other security holder identifier to support easy identification of the deceased's customer record."
                                : "Add any known account or customer number to support easy identification of the deceased's customer record."
                        }
                        fullWidth
                    />

                    {selectedOrganisation?.industry_code === '22' ? (
                        <TextFieldArray
                            sx={{ mb: -1.5 }}
                            label={
                                <Stack direction="row" gap={1}>
                                    <FormLabel id="known-holdings-label">Known holdings</FormLabel>
                                    <InfoTooltip title="Add company (or similar) name of any known security holdings to support easy identification of the deceased's customer record." />
                                </Stack>
                            }
                            itemLabel={(index) => `Known holding ${index + 1}`}
                            name="known_holdings"
                            addMessage="Add a known holding"
                            textFieldElementProps={{
                                onKeyDown: handleKeyDown,
                            }}
                        />
                    ) : null}

                    <TextFieldElement
                        multiline
                        name="comments"
                        label="Comments/Queries/Requests"
                        helperText="Add any relevant comments specific to this Participant that are not covered in any attached document."
                        fullWidth
                        minRows={5}
                        maxRows={10}
                    />

                    <Stack gap={1}>
                        <Stack direction="row" gap={1}>
                            <FormLabel id="upload-letter-label">
                                Attach initial/notification letter?
                            </FormLabel>
                            <InfoTooltip title="Select 'Yes' and upload relevant document if you wish to share a formal letter with the selected Participant." />
                        </Stack>

                        <RadioGroup
                            aria-labelledby="upload-letter-label"
                            name="upload-letter-group"
                            onChange={(e) => setShowLetter(e.target.value as typeof showLetter)}
                            value={showLetter}
                            onKeyDown={handleKeyDown}
                        >
                            <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
                            <FormControlLabel value={'no'} control={<Radio />} label="No" />
                        </RadioGroup>
                        {showLetter === 'yes' && (
                            <DragAndDropFileField
                                name="letter_file"
                                required={false}
                                multiple={false}
                            />
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={onBack}>
                    Back
                </Button>
                <Button type="submit" variant="contained">
                    Invite
                </Button>
            </DialogActions>
        </FormContainer>
    );
};

export default AddParticipantDialog;
