import { Card } from '../../../../components/Card';
import DocumentsTable from '../../../../components/documentsTable/DocumentsTable';
import { DocumentTableRowType } from '../../../../components/documentsTable/DocumentsTableCells';
import ReadonlyField from '../../../../components/fields/ReadonlyField';
import { useWorkspace } from '../../workspaceContext';

function OtherProofOfAuthorityCard() {
    const workspace = useWorkspace();

    return (
        <Card title="Other Proof of Authority" disablePadding>
            <ReadonlyField
                label="Other Proof of Authority files"
                value={
                    workspace.other_poa_docs.length > 0 && (
                        <DocumentsTable
                            documents={[...workspace.other_poa_docs]}
                            extraColumns={[
                                {
                                    id: 'type',
                                    label: 'Type',
                                    Component: DocumentTableRowType,
                                },
                            ]}
                        />
                    )
                }
            />
        </Card>
    );
}

export default OtherProofOfAuthorityCard;
