import { Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface PageLayoutProps extends PropsWithChildren {
    title?: ReactNode;
    subtitle?: ReactNode;
    actions?: ReactNode;
}

function PageLayout({ title, actions, children }: PageLayoutProps) {
    return (
        <Stack
            sx={{
                p: 8,
                pt: 6,
                pr: 7,
                mb: 0,
                borderRadius: 5,
                flexGrow: 1,
                backgroundColor: 'background.paper',
                overflowX: 'hidden',
            }}
        >
            <Stack justifyContent="space-between">
                <Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Typography variant="h2" noWrap sx={{ flexGrow: 1 }} component="div">
                            {title}
                        </Typography>
                        <Stack direction="row"></Stack>
                        {actions}
                    </Stack>
                </Stack>
            </Stack>

            <Stack flexGrow={1}>{children}</Stack>
        </Stack>
    );
}

export default PageLayout;
