import { Chip, ChipProps, Tooltip } from '@mui/material';
import { IconCertificateOff, IconFileCertificate } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { components } from '../api/schema';

type VoiDocument = components['schemas']['VoiDocument'];

interface DocumentVerifiedChipProps extends ChipProps {
    verified?: boolean;
    tooltip?: ReactNode;
}

function DocumentVerifiedChip({ verified, tooltip, ...chipProps }: DocumentVerifiedChipProps) {
    return (
        <Tooltip title={verified && tooltip ? tooltip : null}>
            {verified ? (
                <Chip
                    sx={{ background: 'secondary.main', my: -1 }}
                    icon={<IconFileCertificate size={18} />}
                    label="Verified by DVS"
                    color="secondary"
                    {...chipProps}
                />
            ) : (
                <Chip
                    sx={{ my: -1 }}
                    icon={<IconCertificateOff size={18} />}
                    label="Document Unverified"
                    color="default"
                ></Chip>
            )}
        </Tooltip>
    );
}

export default DocumentVerifiedChip;
