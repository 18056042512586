import { Box, FilledInputProps, IconButton, InputBase, Stack } from '@mui/material';
import { useDebouncedEffect } from '@react-hookz/web';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';

export interface SearchInputProps extends FilledInputProps {
    value?: string;
    forceOpen?: boolean;
    onUpdate?: (value: string) => void;
}

export default function SearchInput({
    value: initialValue = '',
    forceOpen,
    onUpdate,
    ...inputProps
}: SearchInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [query, setQuery] = useState(initialValue);
    const firstUpdateRef = useRef(true);

    const showSearchInput = isFocused || query || forceOpen;

    // Debounce updates to parent
    useDebouncedEffect(
        () => {
            if (!firstUpdateRef.current) {
                onUpdate?.(query);
            }
            firstUpdateRef.current = false;
        },
        [query],
        200
    );

    // Keep query in sync with controlled value prop
    useEffect(() => {
        if (initialValue !== query) {
            setQuery(initialValue);
        }
    }, [initialValue]);

    return (
        <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
            <InputBase
                onClick={() => {
                    if (!isFocused) {
                        inputRef.current?.focus();
                    }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder="Search"
                endAdornment={
                    showSearchInput && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setQuery('');
                                onUpdate?.('');
                            }}
                            size="small"
                            sx={{
                                position: 'absolute',
                                right: 0,
                                mr: 0.5,
                                p: 0.5,
                            }}
                            data-test="clearSearch"
                        >
                            <IconX size={18} />
                        </IconButton>
                    )
                }
                size="small"
                hiddenLabel
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key !== 'Escape' || query) {
                        e.stopPropagation();
                    }
                    if (e.key === 'Escape') {
                        setQuery('');
                        onUpdate?.('');
                    }
                }}
                {...inputProps}
                startAdornment={
                    <Box
                        color="inherit"
                        sx={{
                            opacity: 1,
                            alignItems: 'center',
                            display: 'flex',
                            mr: 0.8,
                        }}
                    >
                        <IconSearch size={19} />
                    </Box>
                }
                sx={{
                    width: showSearchInput ? '280px' : '87px',
                    transition: 'width 400ms ease-in-out',
                    px: 1,
                    py: 0.8,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '22.75px',
                    borderRadius: 30,

                    '& input': {
                        padding: '0 !important',
                    },

                    '& input::placeholder': {
                        color: 'inherit',
                        opacity: showSearchInput ? 0.5 : 1,
                        fontWeight: 400,
                    },

                    '&:hover': {
                        backgroundColor: 'action.hover',
                        ...(showSearchInput && {
                            backgroundColor: 'action.selected',
                        }),
                    },

                    ...(showSearchInput && {
                        backgroundColor: 'action.selected',
                    }),

                    '&.Mui-focused': {
                        backgroundColor: 'action.focus',
                    },

                    ...inputProps.sx,
                }}
                inputProps={{
                    ref: inputRef,
                }}
            />
        </Stack>
    );
}
