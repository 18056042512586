import { Grid, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { deceasedDetailQuery } from '../../../api/deceasedDetail';
import LoadingArea from '../../../components/LoadingArea';
import ReadonlyField from '../../../components/fields/ReadonlyField';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

function EstateRepresentative() {
    const workspace = useWorkspace();
    const { data: details } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));

    if (details === undefined) return <LoadingArea />;

    const organisation = details.estate_representative.organisation;

    const addressLines: String[] = [];
    if (organisation.address_line_1) addressLines.push(organisation.address_line_1);
    if (organisation.address_line_2) addressLines.push(organisation.address_line_2);
    const address: String = addressLines.join(', ');

    return (
        <PageLayout title="Professional Representative">
            <Stack gap={4} sx={{ mt: 4 }}>
                <Grid container spacing={5}>
                    <ReadonlyField
                        label="Organisation name"
                        value={details.estate_representative.organisation?.name || ''}
                    />
                    <ReadonlyField
                        label="ACN"
                        value={details.estate_representative.organisation?.acn || ''}
                    />

                    <ReadonlyField
                        label="Trading name"
                        value={details.estate_representative.organisation?.trading_name || ''}
                    />
                    <ReadonlyField
                        label="ABN"
                        value={details.estate_representative.organisation?.abn || ''}
                    />

                    <ReadonlyField label="Registered business address" value={address} />
                    <ReadonlyField label="" value=" " />

                    <ReadonlyField
                        label="Contact name"
                        value={details.estate_representative.organisation.contact_name || ''}
                    />
                    <ReadonlyField label="" value=" " />

                    <ReadonlyField
                        label="Phone number"
                        value={
                            details.estate_representative.organisation.contact_phone_number || ''
                        }
                    />
                    <ReadonlyField label="" value=" " />

                    <ReadonlyField
                        label="Email address"
                        value={details.estate_representative.organisation.contact_email || ''}
                    />
                    <ReadonlyField label="" value=" " />
                </Grid>
            </Stack>
        </PageLayout>
    );
}

export default EstateRepresentative;
