import { Box, Stack, TableCell } from '@mui/material';
import { Notification } from '../../../api/notification';
import DateTimeString from '../../../components/DateTimeString';
import { Column } from '../../../components/table/genericTable';
import UpdateActions from './UpdateActions';
import { notificationDescription, notificationIcon } from './utils';

export const columns: Column<Notification>[] = [
    {
        id: 'description',
        label: 'Description',
        sortable: 'description',
        Component: ({ object: notification }) => (
            <TableCell>
                <Stack direction="row" gap={1} alignItems="center">
                    <Box
                        stroke={1.5}
                        component={notificationIcon[notification.type]}
                        size={22}
                        flexShrink={0}
                    />
                    {notificationDescription(notification)}
                </Stack>
            </TableCell>
        ),
    },
    {
        id: 'created_at',
        label: 'Date',
        sortable: 'created_at',
        Component: ({ object: notification }) => (
            <TableCell>{DateTimeString(notification.created_at)}</TableCell>
        ),
    },
    {
        id: 'triggered_by',
        label: 'Participant',
        sortable: 'triggered_by_organisation_name',
        Component: ({ object: notification }) => (
            <TableCell>{notification.triggered_by_organisation_name}</TableCell>
        ),
    },
    {
        id: 'actions',
        Component: ({ object: notification }) => <UpdateActions notification={notification} />,
        sx: { width: 0 },
    },
];
