import { useQuery } from '@tanstack/react-query';
import { deceasedDetailQuery } from '../../../../api/deceasedDetail';
import { components } from '../../../../api/schema';
import { Card } from '../../../../components/Card';
import DocumentsTable from '../../../../components/documentsTable/DocumentsTable';
import ReadonlyField from '../../../../components/fields/ReadonlyField';
import { useWorkspace } from '../../workspaceContext';

type HasWillEnum = components['schemas']['HasWillEnum'];

const hasWillLabels: Record<HasWillEnum, string> = {
    yes: 'Yes',
    no: 'No',
    unknown: 'Unknown',
    yes_but: 'Yes, but...',
};

function WillCard() {
    const workspace = useWorkspace();
    const { data: details } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));

    const hasWill = details?.has_will === 'yes' || details?.has_will === 'yes_but';

    return (
        <Card title="Will">
            <ReadonlyField
                label="Did the deceased leave a will?"
                value={details?.has_will ? hasWillLabels[details?.has_will] : undefined}
            />
            {details?.has_will === 'yes_but' && (
                <ReadonlyField label="Additional detail" value={details?.will_additional_details} />
            )}

            {hasWill && (
                <ReadonlyField
                    xs={12}
                    label="Will documents"
                    value={
                        workspace.will ? <DocumentsTable documents={[workspace.will]} /> : undefined
                    }
                />
            )}
        </Card>
    );
}

export default WillCard;
