import { LoadingButton } from '@mui/lab';
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { markAllNotificationsReadMutation } from '../../../api/notification';

interface ClearAllUpdatesDialogProps extends DialogProps {
    workspaceId: string;
    onClose?: () => void;
}

function ClearAllUpdatesDialog({
    workspaceId,
    onClose,
    ...dialogProps
}: ClearAllUpdatesDialogProps) {
    const mutation = useMutation(markAllNotificationsReadMutation(workspaceId));

    const handleClear = () => {
        mutation.mutate(void 0, {
            onSuccess: () => {
                onClose?.();
            },
        });
    };

    return (
        <Dialog onClose={onClose} {...dialogProps}>
            <DialogTitle>Clear All Updates</DialogTitle>
            <DialogContent>
                <Card>
                    Are you sure you want to clear all updates? This action cannot be undone.
                </Card>
            </DialogContent>

            <DialogActions>
                <Button color="inherit" onClick={onClose}>
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    color="error"
                    onClick={handleClear}
                    loading={mutation.isPending}
                >
                    Clear All
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ClearAllUpdatesDialog;
