import { ArrowForward } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import {
    IconFileCertificate,
    IconFileTypeCsv,
    IconGavel,
    IconUserScan,
    IconZoomCheck,
} from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import IDVerseBadge from '../../components/IDVerseBadge';
import SearchInput from '../../components/SearchInput';
import useUser from '../../hooks/useUser';

const iconSize = 28;

interface ToolCardProps {
    name: string;
    description: React.ReactNode;
    theme: 'Proof of Death' | 'Proof of Authority' | 'Proof of Identity';
    icon: JSX.Element;
    link: string;
    permission?: string;
}

const tools: ToolCardProps[] = [
    {
        name: 'Death Certificate Verification',
        description: 'Confirm Death Certificate fields match official records.',
        theme: 'Proof of Death',
        icon: <IconFileCertificate size={iconSize} />,
        link: 'death-certificate-verification',
        permission: 'death_cert_verification',
    },
    {
        name: 'Fact of Death Lookup (Single)',
        description: 'Confirm whether a death has been registered in Australia.',
        theme: 'Proof of Death',
        icon: <IconZoomCheck size={iconSize} />,
        link: 'fact-of-lookup-single',
        permission: 'death_check_single',
    },
    {
        name: 'Fact of Death Lookup (Bulk)',
        description:
            'Confirm whether a death has been registered in Australia - bulk lookup  (10+).',
        theme: 'Proof of Death',
        icon: <IconFileTypeCsv size={iconSize} />,
        link: 'fact-of-death-lookup-bulk',
        permission: 'death_check_bulk',
    },
    {
        name: 'Grant of Representation Retrieval (VIC)',
        description: 'Access digital court-issued grant of representation.',
        theme: 'Proof of Authority',
        icon: <IconGavel size={iconSize} />,
        link: 'victorian-grant-of-representation-lookup',
        permission: 'gor_retrieval',
    },
    {
        name: 'Verification of Identity',
        description: (
            <Stack gap={1}>
                <IDVerseBadge />
                Request an electronic Verification of Identity.
            </Stack>
        ),
        theme: 'Proof of Identity',
        icon: <IconUserScan size={iconSize} />,
        link: 'electronic-verification-of-identity',
        permission: 'e_voi',
    },
];

function canViewTool(permissions: string[], tool: ToolCardProps) {
    if (tool.permission) {
        return permissions.includes(tool.permission);
    }

    return false;
}

const StandaloneTools = () => {
    const user = useUser();
    const [searchTerm, setSearchTerm] = useState('');

    user?.computed_permissions;

    const filteredTools = useMemo(() => {
        return tools.filter((tool) => {
            if (!canViewTool(user?.computed_permissions || [], tool)) {
                return false;
            } else {
                return (
                    searchTerm === '' || tool.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }
        });
    }, [searchTerm, user?.computed_permissions]);

    return (
        <Stack
            sx={{
                flexGrow: 1,
                backgroundColor: 'background.default',
                overflow: 'auto',
            }}
        >
            <Container maxWidth="lg">
                <Helmet>Standalone Tools</Helmet>
                <Stack
                    sx={{
                        backgroundColor: 'background.default',
                        zIndex: 'appBar',
                        position: 'sticky',
                        top: 0,
                        pt: 3,
                        mb: 2,
                    }}
                >
                    <Breadcrumbs>
                        <Typography key="2" color="text.primary">
                            Standalone Tools
                        </Typography>
                    </Breadcrumbs>
                    <Stack direction="row" alignItems="baseline" gap={2}>
                        <Typography variant="h3">Standalone Tools</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <SearchInput
                            value={searchTerm}
                            onUpdate={(q) => {
                                setSearchTerm(q);
                            }}
                        />
                    </Stack>
                </Stack>

                <Grid container spacing={4} sx={{ pb: 1 }}>
                    {filteredTools.map((tool, index) => (
                        <ToolCard key={index} {...tool} />
                    ))}
                </Grid>
            </Container>
        </Stack>
    );
};

const ToolCard = ({ icon, name, description, link }: ToolCardProps) => {
    return (
        <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '25ch' }}>
            <Card
                sx={{
                    backgroundColor: 'background.paper',
                    height: '100%',
                    borderRadius: 5,
                    p: 1.8,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardContent sx={{ flexGrow: 1 }}>
                    <Stack gap={0.8}>
                        {icon}
                        <Stack direction={'row'} alignItems={'center'} gap={2}>
                            <Typography variant="h4" fontSize="1.5rem">
                                {name}
                            </Typography>
                        </Stack>
                        <Typography
                            variant="body2"
                            component="p"
                            color="text.secndary"
                            sx={{ pt: 0.75, pl: 0.2 }}
                        >
                            {description}
                        </Typography>
                    </Stack>
                </CardContent>
                <CardActions sx={{ pl: 1.75, pt: 0 }}>
                    <Button
                        component={RouterLink}
                        to={link || ''}
                        color="inherit"
                        variant="outlined"
                        startIcon={<ArrowForward />}
                    >
                        Go
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default StandaloneTools;
