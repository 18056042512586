import { Button, FormHelperText, Stack, TableCell } from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { useFormContext } from 'react-hook-form';
import {
    RelatedPersonMeta,
    relatedPeopleQuery,
    relatedPersonRoleLabels,
} from '../../../../api/relatedPerson';
import { Card } from '../../../../components/Card';
import QueryProgress from '../../../../components/QueryProgress';
import RelatedPersonDrawer from '../../../../components/relatedPeople/RelatedPersonDrawer';
import GenericTable, {
    CellComponentProps,
    Column,
} from '../../../../components/table/genericTable';
import { useWorkspace } from '../../workspaceContext';

const columns: Column<RelatedPersonMeta>[] = [
    {
        id: 'name',
        label: 'Name',
        Component: ({ object: person }: CellComponentProps<RelatedPersonMeta>) => (
            <TableCell>{person.full_name}</TableCell>
        ),
    },
    {
        id: 'role',
        label: 'Role',
        Component: ({ object: person }: CellComponentProps<RelatedPersonMeta>) => (
            <TableCell>
                {person.role === 'other'
                    ? person.role_detail
                    : relatedPersonRoleLabels[person.role]}
            </TableCell>
        ),
    },
];

function LegalPersonalRepresentativesCard() {
    const { showModal } = useModal();
    const workspace = useWorkspace();

    const peopleQuery = useQuery(relatedPeopleQuery(workspace.id));
    const personalRepresentatives =
        peopleQuery.data?.results.filter((person) => person.is_lpr) || [];

    const {
        trigger,
        formState: { errors },
    } = useFormContext();

    // Retrigger field validation when the list of personal representatives changes
    useUpdateEffect(() => {
        trigger('personal_representatives');
    }, [personalRepresentatives?.length]);

    return (
        <Card title="Personal Representatives">
            <Stack gap={1}>
                <QueryProgress query={peopleQuery}>
                    {personalRepresentatives.length > 0 ? (
                        <GenericTable<RelatedPersonMeta>
                            defaultSort=""
                            objects={personalRepresentatives}
                            columns={columns}
                            onRowClick={(relatedPerson) => {
                                showModal(RelatedPersonDrawer, {
                                    workspace,
                                    person: relatedPerson,
                                });
                            }}
                        />
                    ) : null}
                </QueryProgress>
                <Stack>
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ maxWidth: 'fit-content' }}
                        onClick={() =>
                            showModal(RelatedPersonDrawer, {
                                workspace,
                                assumeLpr: true,
                            })
                        }
                    >
                        Add Personal Representative
                    </Button>

                    {errors.personal_representatives && (
                        <FormHelperText
                            error
                            sx={{ ml: 1.5 }}
                        >{`${errors.personal_representatives?.message}`}</FormHelperText>
                    )}
                </Stack>
            </Stack>
        </Card>
    );
}

export default LegalPersonalRepresentativesCard;
