import { Stack } from '@mui/material';
import { components } from '../../../../api/schema';
import { Card } from '../../../../components/Card';
import InfoTooltip from '../../../../components/InfoTooltip';
import DocumentsTable from '../../../../components/documentsTable/DocumentsTable';
import {
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowJurisdiction,
    DocumentTableRowPoAUploadedBy,
} from '../../../../components/documentsTable/DocumentsTableCells';
import ReadonlyField from '../../../../components/fields/ReadonlyField';
import { useWorkspace } from '../../workspaceContext';

type GorStatusEnum = components['schemas']['GorStatusEnum'];

const gorStatusLabels: Record<GorStatusEnum, string> = {
    unsure: 'Not sure yet',
    no_intention: 'No',
    not_applied: 'Yes - Intend to apply',
    pending: 'Yes - Application submitted',
    approved: 'Yes - Grant already issued',
    rejected: 'Yes - Application rejected',
};

function GrantOfRepresentationCard() {
    const workspace = useWorkspace();

    return (
        <Card title="Grant of representation">
            <ReadonlyField
                label={
                    <Stack direction="row" gap={1}>
                        Intention to apply
                        <InfoTooltip
                            title="Indication by the Professional Representative of their intention to apply for
                        grant of representation."
                            size={20}
                        />
                    </Stack>
                }
                value={workspace.gor_status ? gorStatusLabels[workspace.gor_status] : undefined}
            />

            <ReadonlyField
                label="Grant of representation documents"
                notProvided="Not applicable"
                value={
                    workspace.grants_of_representation?.length > 0 && (
                        <DocumentsTable
                            documents={[...workspace.grants_of_representation]}
                            columns={[
                                {
                                    id: 'file',
                                    label: 'File',
                                    Component: DocumentTableRowFile,
                                },
                                {
                                    id: 'jursidiction',
                                    label: 'Jurisdiction',
                                    Component: DocumentTableRowJurisdiction,
                                },
                                {
                                    id: 'createdAt',
                                    label: 'Uploaded',
                                    Component: DocumentTableRowCreatedAt,
                                },
                                {
                                    id: 'uploadedBy',
                                    label: 'Uploaded by',
                                    Component: DocumentTableRowPoAUploadedBy,
                                },
                            ]}
                        />
                    )
                }
            />
        </Card>
    );
}

export default GrantOfRepresentationCard;
