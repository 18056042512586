import { TableCell } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import {
    RelatedPersonMeta,
    relatedPeopleQuery,
    relatedPersonRoleLabels,
} from '../../../../api/relatedPerson';
import { Card } from '../../../../components/Card';
import QueryProgress from '../../../../components/QueryProgress';
import RelatedPersonDrawer from '../../../../components/relatedPeople/RelatedPersonDrawer';
import GenericTable, {
    CellComponentProps,
    Column,
} from '../../../../components/table/genericTable';
import { useWorkspace } from '../../workspaceContext';

const columns: Column<RelatedPersonMeta>[] = [
    {
        id: 'name',
        label: 'Name',
        Component: ({ object: person }: CellComponentProps<RelatedPersonMeta>) => (
            <TableCell>{person.full_name}</TableCell>
        ),
    },
    {
        id: 'role',
        label: 'Role',
        Component: ({ object: person }: CellComponentProps<RelatedPersonMeta>) => (
            <TableCell>
                {person.role === 'other'
                    ? person.role_detail
                    : relatedPersonRoleLabels[person.role]}
            </TableCell>
        ),
    },
];

function LegalPersonalRepresentativesCard() {
    const { showModal } = useModal();
    const workspace = useWorkspace();

    const peopleQuery = useQuery(relatedPeopleQuery(workspace.id));
    const personalRepresentatives =
        peopleQuery.data?.results.filter((person) => person.is_lpr) || [];

    return (
        <Card title="Personal Representatives">
            <QueryProgress query={peopleQuery}>
                <GenericTable<RelatedPersonMeta>
                    defaultSort=""
                    objects={personalRepresentatives}
                    columns={columns}
                    onRowClick={(relatedPerson) => {
                        showModal(RelatedPersonDrawer, {
                            workspace,
                            person: relatedPerson,
                        });
                    }}
                />
            </QueryProgress>
        </Card>
    );
}

export default LegalPersonalRepresentativesCard;
