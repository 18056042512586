import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { IconArrowBackUp, IconExternalLink } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    DatePickerElement,
    FormContainer,
    SelectElement,
    TextFieldElement,
} from 'react-hook-form-mui';
import { z } from 'zod';
import {
    DeathCheckSingleRequest,
    DeathCheckSingleResponse,
    deathCheckMutation,
} from '../../api/standaloneTools/FactOfDeath';
import { dateString } from '../../api/utils';
import ADCBadge from '../../components/ADCBadge';
import DateString from '../../components/DateString';
import DateTimeString from '../../components/DateTimeString';
import DownloadButton from '../../components/DownloadButton';
import FormGrid from '../../components/FormGrid';
import Placeholder from '../../components/Placeholder';
import { colors } from '../../theme/palette';
import {
    ValidationMessages,
    characterLimit,
    noNumbers,
    noSpecialChars,
    optionalDate,
    optionalString,
    requiredDate,
    requiredString,
} from '../../validationRules';
import { dataSets } from './FactOfDeathDataSets';
import ToolPageLayout from './ToolPageLayout';

const validationSchema = z
    .object({
        given_names: requiredString.and(characterLimit(70)).and(noNumbers).and(noSpecialChars),
        family_name: requiredString.and(characterLimit(40)).and(noNumbers).and(noSpecialChars),
        date_type: requiredString,
        day: optionalDate,
        month: optionalDate,
        year: requiredDate,
        reference: optionalString.and(characterLimit(16)).and(noSpecialChars),
    })
    .refine(
        //if month is provided then day is required
        (fields) => (fields.month ? !!fields.day : true),
        {
            message: ValidationMessages.REQUIRED,
            path: ['day'],
        }
    )
    .refine(
        //if day is provided then month is required
        (fields) => (fields.day ? !!fields.month : true),
        {
            message: ValidationMessages.REQUIRED,
            path: ['month'],
        }
    );

type FactOfDeathSingleToolFields = z.infer<typeof validationSchema>;

const FactOfDeathSingleTool = () => {
    const mutation = useMutation(deathCheckMutation());

    const formMethods = useForm<FactOfDeathSingleToolFields>({
        mode: 'onChange',
        defaultValues: {
            given_names: '',
            family_name: '',
            date_type: '',
            day: undefined,
            month: undefined,
            year: undefined,
            reference: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const {
        watch,
        reset,
        formState: { isDirty },
    } = formMethods;

    const watchDataMatchingMethod = watch('date_type');

    const handleSubmit = async () => {
        const values = formMethods.getValues();

        let request: DeathCheckSingleRequest = {
            data_set: 'ADCEXACTNAMEPARTDOB',
            given_names: values.given_names,
            family_name: values.family_name,
            user_reference: values.reference,
        };

        const year = (values.year as Date).getFullYear();

        let fullDate;
        if (!!values.day && !!values.month) {
            const day = (values.day as Date).getDate();
            const month = (values.month as Date).getMonth();
            fullDate = dateString(new Date(year, month, day));
        }

        if (values.date_type === 'DOB') {
            if (!!fullDate) {
                request.data_set = 'ADCPARTNAMEEXACTDOB';
                request.date_of_birth = fullDate;
            } else {
                request.data_set = 'ADCEXACTNAMEPARTDOB';
                request.year_of_birth = year;
            }
        } else if (values.date_type === 'DOD') {
            if (!!fullDate) {
                request.data_set = 'ADCEXACTNAMEDOD';
                request.date_of_death = fullDate;
            } else {
                request.data_set = 'ADCEXACTNAMEPARTDOD';
                request.year_of_death = year;
            }
        }

        mutation.mutate(request);
    };

    const handleNewSearch = () => {
        mutation.reset();
    };

    return (
        <ToolPageLayout
            title="Fact of Death Lookup (Single)"
            description="Confirm whether a death has been registered in Australia"
        >
            <Paper
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    borderRadius: 5,
                    p: 7,
                }}
            >
                {!mutation.data ? (
                    <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                        <Stack gap={3}>
                            <FormGrid>
                                <Grid item xs={6}>
                                    <TextFieldElement
                                        autoFocus
                                        name="given_names"
                                        label="Given names"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldElement
                                        name="family_name"
                                        label="Family name"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectElement
                                        name="date_type"
                                        label="Date type"
                                        valueKey="value"
                                        options={[
                                            {
                                                id: '1',
                                                label: 'Date of birth',
                                                value: 'DOB',
                                            },
                                            {
                                                id: '3',
                                                label: 'Date of death',
                                                value: 'DOD',
                                            },
                                        ]}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack direction="row" gap={2}>
                                        <DatePickerElement
                                            name="day"
                                            label="Day"
                                            disabled={!watchDataMatchingMethod}
                                            views={['day']}
                                        />
                                        <DatePickerElement
                                            name="month"
                                            label="Month"
                                            disabled={!watchDataMatchingMethod}
                                            views={['month']}
                                        />
                                        <DatePickerElement
                                            name="year"
                                            label="Year"
                                            disabled={!watchDataMatchingMethod}
                                            views={['year']}
                                            maxDate={new Date()}
                                            required
                                        />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            pt: 3,
                                            mt: 1,
                                            borderTop: '2px solid',
                                            borderTopColor: 'action.selected',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextFieldElement
                                        id="reference"
                                        name="reference"
                                        label="Your internal reference"
                                        helperText="Optional. Maximum 16 letters, numbers, and symbols."
                                        fullWidth
                                    />
                                </Grid>
                            </FormGrid>

                            <Prompt />

                            <Stack direction="row" justifyContent="space-between" gap={1}>
                                <Stack direction="row" gap={1}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        loading={mutation.status === 'pending'}
                                        disabled={mutation.status === 'pending'}
                                    >
                                        Submit
                                    </LoadingButton>
                                    {isDirty && (
                                        <Button color="inherit" onClick={() => reset()}>
                                            Reset
                                        </Button>
                                    )}
                                </Stack>
                                <ADCBadge />
                            </Stack>
                        </Stack>
                    </FormContainer>
                ) : (
                    <FactOfDeathSingleResults
                        response={mutation.data}
                        handleNewSearch={handleNewSearch}
                    />
                )}
            </Paper>
        </ToolPageLayout>
    );
};

const FactOfDeathSingleResults = ({
    response,
    handleNewSearch,
}: {
    response: DeathCheckSingleResponse;
    handleNewSearch: () => void;
}) => {
    return (
        <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={3}>
                <Typography variant="h4">Search Results</Typography>
                <Box flexGrow={1} />
                {response.result && (
                    <DownloadButton url={response.result} startIcon={<IconExternalLink />}>
                        View results
                    </DownloadButton>
                )}
                <Button
                    variant="contained"
                    startIcon={<IconArrowBackUp />}
                    onClick={handleNewSearch}
                >
                    New search
                </Button>
            </Stack>
            <Typography variant="body2">Search conducted {DateTimeString(new Date())}</Typography>
            <Typography variant="body2">
                Data set used: {dataSets[response.data_set].title}
            </Typography>
            {response.matches.length === 0 ? (
                <Placeholder
                    title="No records found"
                    description="No matches were found in the Australian Death Check records"
                />
            ) : (
                <TableContainer component={Paper} sx={{ mx: 0.1 }}>
                    <Table
                        sx={{
                            'border-collapse': 'separate',
                            'border-spacing': '0 5px',
                            border: 'transparent',
                            borderRadius: 5,
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& > *': {
                                        border: 'none !important',
                                        pb: '0 !important',
                                    },
                                }}
                            >
                                <TableCell>Given Names</TableCell>
                                <TableCell>Family Name</TableCell>
                                <TableCell>Date of Death</TableCell>
                                <TableCell>Registration State</TableCell>
                                <TableCell>ACR Reference</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                border: 'none !important',
                                backgroundColor: colors.snow,
                                '& > *': { '& > *': { border: 'none !important' } },
                                '& > *:nth-child(odd)': {
                                    backgroundColor: colors.sand,
                                },
                            }}
                        >
                            {response.matches.map((match) => (
                                <TableRow key={match.acr_reference}>
                                    <TableCell>{match.given_names}</TableCell>
                                    <TableCell>{match.family_name}</TableCell>
                                    <TableCell>{DateString(match.date_of_death)}</TableCell>
                                    <TableCell>{match.registration_state}</TableCell>
                                    <TableCell>{match.acr_reference}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Stack>
    );
};

const Prompt = () => {
    const [showMore, setShowMore] = useState(true);
    return (
        <Alert severity="info" sx={{}}>
            Fact of Death Lookup will run the best available check based on the data input.
            <Button
                color="inherit"
                onClick={() => setShowMore(!showMore)}
                sx={{ ml: 1 }}
                size="small"
            >
                Show {showMore ? 'less' : 'more'}
            </Button>
            <Collapse in={showMore}>
                <Stack gap={1}>
                    <ul>
                        <li>
                            Where full date of birth is provided, a broad check will be completed on
                            the name (middle names are not required).
                        </li>
                        <li>
                            Where date of death or only year of birth are provided, an exact check
                            will be completed on the name (i.e. if you omit middle names present in
                            the official death record a match may not be made).
                        </li>
                    </ul>
                    For a broad lookup, most likely to match to your customer, enter the full date
                    of birth where available.
                    <br />
                    <br />
                    <Typography variant="body2" sx={{ pb: 1 }} fontWeight="bold">
                        Your organisation will be billed for each time a query is submitted.
                    </Typography>
                </Stack>
            </Collapse>
        </Alert>
    );
};

export default FactOfDeathSingleTool;
