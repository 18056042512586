import { IconButton, TableCell } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { useModal } from 'mui-modal-provider';
import { NoteMeta } from '../../../api/notes';
import DateTimeString from '../../../components/DateTimeString';
import TableRowControls from '../../../components/TableRowControls';
import { Column } from '../../../components/table/genericTable';
import useUser from '../../../hooks/useUser';
import DeleteNoteDialog from './DeleteNoteDialog';

export const columns: Column<NoteMeta>[] = [
    {
        id: 'theme',
        label: 'Theme',
        sortable: 'theme',
        Component: ({ object: note }) => <TableCell>{note.theme}</TableCell>,
    },
    {
        id: 'content',
        label: 'Content',
        Component: ({ object: note }) => (
            <TableCell>
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2' /* number of lines to show */,
                        lineClamp: '2' /* fallback for other browsers */,
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'normal',
                    }}
                >
                    {note.content}
                </div>
            </TableCell>
        ),
    },
    {
        id: 'created_by',
        label: 'Left By',
        Component: ({ object: note }) => <TableCell>{note.created_by.name}</TableCell>,
    },
    {
        id: 'updated',
        label: 'Updated',
        sortable: 'created_at',
        Component: ({ object: note }) => <TableCell>{DateTimeString(note.created_at)}</TableCell>,
    },
    {
        id: 'actions',
        Component: ({ object: note }) => {
            const user = useUser();
            const { showModal } = useModal();
            const isNoteCreatedByUser = note.created_by.email === user?.email;

            return (
                <TableRowControls>
                    {isNoteCreatedByUser ? (
                        <IconButton
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                return showModal(DeleteNoteDialog, { note });
                            }}
                        >
                            <IconTrash />
                        </IconButton>
                    ) : null}
                </TableRowControls>
            );
        },
        sx: { width: '3.5rem' },
    },
];
