import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Stack,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import MuiPhoneNumber from 'material-ui-phone-number';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import { eVoITransactionsQuery, initiateVoITransactionMutation } from '../../api/eVoiTransaction';
import { queryClient } from '../../api/queryClient';
import { characterLimit, noSpecialChars, optionalString, phoneNumber } from '../../validationRules';
import IDVerseBadge from '../IDVerseBadge';
import RadioButtonGroupField from '../fields/RadioButtonGroupField';
import { FlowTypeNormal, FlowTypeV2 } from './terms';

interface InitiateVoIDialogProps extends DialogProps {
    relatedPersonId?: string;
    onSubmit?: () => void;
    onClose?: () => void;
}

const validationSchema = z.object({
    // TODO: Temporary make these optional string otherwise triggering e-VoI in workspace context will always fail
    givenName: optionalString,
    familyName: optionalString,
    phoneNumber: phoneNumber,
    flowType: z.enum(['NORMAL2', 'VOI2']),
    reference: optionalString.and(characterLimit(16)).and(noSpecialChars),
});

type FormValues = z.infer<typeof validationSchema>;

const InitiateVoIDialog = ({
    onClose,
    onSubmit,
    relatedPersonId,
    ...props
}: InitiateVoIDialogProps) => {
    const formMethods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            givenName: '',
            familyName: '',
            flowType: 'NORMAL2',
            phoneNumber: '',
            reference: '',
        },
        resolver: zodResolver(validationSchema),
    });

    const mutation = useMutation(initiateVoITransactionMutation());

    const handleSubmit = () => {
        const values = formMethods.getValues();

        mutation
            .mutateAsync({
                related_person: relatedPersonId,
                phone_number: values.phoneNumber,
                input_given_names: values.givenName || undefined,
                input_family_name: values.familyName || undefined,
                flow_type: values.flowType,
                user_reference: values.reference || undefined,
            })
            .then(() => {
                queryClient.invalidateQueries(eVoITransactionsQuery());
                onSubmit?.();
                onClose?.();
                enqueueSnackbar('Verification of Identity request sent.', {
                    variant: 'success',
                });
            });
    };

    return (
        <Dialog maxWidth="sm" fullWidth {...props} scroll="body">
            <Stack direction="row" alignItems="baseline">
                <DialogTitle>Initiate Verification of Identity</DialogTitle>
            </Stack>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent sx={{ pt: 0 }}>
                    <Stack gap={2}>
                        {!relatedPersonId ? (
                            <>
                                <TextFieldElement
                                    id="givenName"
                                    name="givenName"
                                    label="Given name(s)"
                                    fullWidth
                                    required
                                />
                                <TextFieldElement
                                    id="familyName"
                                    name="familyName"
                                    label="Family name"
                                    fullWidth
                                    required
                                />
                            </>
                        ) : null}
                        <Controller
                            name="phoneNumber"
                            control={formMethods.control}
                            defaultValue=""
                            render={({ field }) => (
                                <MuiPhoneNumber
                                    {...field}
                                    label="Phone number"
                                    defaultCountry="au"
                                    variant="filled"
                                    required
                                    fullWidth
                                    onChange={(value) => field.onChange(value)}
                                />
                            )}
                        />

                        <Alert severity="info" sx={{ mt: -2 }}>
                            Please ensure you advise the ID provider to expect an SMS
                        </Alert>
                        <RadioButtonGroupField
                            name="flowType"
                            label="Flow type"
                            options={[
                                { label: FlowTypeNormal, value: 'NORMAL2' },
                                { label: FlowTypeV2, value: 'VOI2' },
                            ]}
                            valueKey="value"
                            required
                        />

                        {!relatedPersonId ? (
                            <Box
                                sx={{
                                    mt: 1,
                                    pt: 4,
                                    borderTop: '2px solid',
                                    borderTopColor: 'action.selected',
                                }}
                            >
                                <TextFieldElement
                                    id="reference"
                                    name="reference"
                                    label="Your internal reference"
                                    helperText="Optional. Maximum 16 letters, numbers, and symbols."
                                    fullWidth
                                />
                            </Box>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <IDVerseBadge />
                    <Box sx={{ flexGrow: 1 }} />
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={mutation.status === 'pending'}
                        disabled={mutation.status === 'pending'}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
};

export default InitiateVoIDialog;
