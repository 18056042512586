import { RouteObject, redirect } from 'react-router-dom';
import { fetchUser } from '../api/user';
import Error from '../components/Error';
import Workspace, { loader as workspaceLoader } from '../pages/workspace/Workspace';
import Notes from '../pages/workspace/notes/Notes';
import { participantIssues } from '../pages/workspace/requests';
import Requests from '../pages/workspace/requests/Requests';
import Updates from '../pages/workspace/updates/Updates';
import AccountFinalisation from '../pages/workspace/workspaceMember/AccountFinalisation';
import AccountInstructions from '../pages/workspace/workspaceMember/AccountInstructions';
import EstateDetails from '../pages/workspace/workspaceMember/EstateDetails';
import EstateRepresentative from '../pages/workspace/workspaceMember/EstateRepresentative';
import ProofOfDeath from '../pages/workspace/workspaceMember/ProofOfDeath';
import StatementOfPosition from '../pages/workspace/workspaceMember/StatementOfPosition';
import ProofOfAuthority from '../pages/workspace/workspaceMember/proofOfAuthority/ProofOfAuthority';
import ProofOfIdentity from '../pages/workspace/workspaceMember/proofOfIdentity/ProofOfIdentity';

const workspaceMemberRoutes: RouteObject = {
    id: 'workspaceMember',
    path: 'workspace/m/:workspaceId',
    element: <Workspace />,

    loader: async (loaderFunctionArgs) => {
        const {
            params: { workspaceId },
        } = loaderFunctionArgs;

        // Assume the user is the owner of the workspace if their organisation is a
        // deceased representative organisation
        const user = await fetchUser();
        if (!!user?.de_rep_organisation) {
            throw redirect(`/workspace/${workspaceId}`);
        }

        return workspaceLoader(loaderFunctionArgs);
    },
    children: [
        {
            errorElement: <Error />,
            children: [
                {
                    path: '',
                    element: <EstateDetails />,
                },
                {
                    path: 'estate-representative',
                    element: <EstateRepresentative />,
                },
                {
                    path: 'pod',
                    element: <ProofOfDeath />,
                },
                {
                    path: 'poa',
                    element: <ProofOfAuthority />,
                },
                {
                    path: 'poi',
                    element: <ProofOfIdentity />,
                },
                {
                    path: 'account-instructions',
                    element: <AccountInstructions />,
                },
                {
                    path: 'position',
                    element: <StatementOfPosition />,
                },
                {
                    path: 'finalisation',
                    element: <AccountFinalisation />,
                },
                {
                    path: 'requests',
                    element: (
                        <Requests issues={participantIssues} userType="institution_employee" />
                    ),
                },
                {
                    path: 'notes',
                    element: <Notes />,
                },
                {
                    path: 'updates',
                    element: <Updates />,
                },
            ],
        },
    ],
};

export default workspaceMemberRoutes;
