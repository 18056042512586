import { Chip, ChipProps, Menu, MenuItem, SelectChangeEvent } from '@mui/material';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { anchorRef, usePopupState } from 'material-ui-popup-state/hooks';
import { useId } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface SearchParamSelectOption {
    id: string;
    label: string;
}

interface SearchParamSelectProps extends ChipProps {
    // The parameter that is looked up in the url bar
    parameter: string;
    // A list of optional ids to filter the list by
    options: SearchParamSelectOption[];
    placeholder?: string;
}

function SearchParamSelect({
    parameter,
    options,
    placeholder,
    ...chipProps
}: SearchParamSelectProps) {
    const popupState = usePopupState({ variant: 'popover', popupId: useId() });
    const [searchParams, setSearchParams] = useSearchParams();

    const param = searchParams.get(parameter);
    const selectedOption = options.find((option) => option.id === param);

    const handleSetParams = (e: SelectChangeEvent) => {
        const params = new URLSearchParams(searchParams);
        const participant = e.target.value as string;
        if (participant === '') {
            params.delete(parameter);
        } else {
            params.set(parameter, e.target.value as string);
        }
        setSearchParams(params);
    };

    return (
        <>
            <Chip
                label={selectedOption?.label || placeholder}
                color="primary"
                clickable
                onDelete={(e) => {
                    if (!selectedOption) {
                        popupState.open();
                    } else {
                        const params = new URLSearchParams(searchParams);
                        params.delete(parameter);
                        setSearchParams(params);
                    }
                }}
                deleteIcon={selectedOption ? <IconX size={20} /> : <IconChevronDown />}
                {...chipProps}
                sx={{
                    maxWidth: 0,
                    minWidth: 190,
                    borderRadius: 100,
                    bgcolor: selectedOption ? 'primary.dark' : 'primary.main',
                    ...chipProps.sx,
                }}
                // Anchor the menu to the chip, rather than to the delete icon
                ref={anchorRef(popupState)}
                {...bindTrigger(popupState)}
            />
            <Menu {...bindMenu(popupState)}>
                {options?.map((option) => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        onClick={() => {
                            handleSetParams({
                                target: { value: option.id },
                            } as SelectChangeEvent);
                            popupState.close();
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
                {!options.length && <MenuItem disabled>No options available</MenuItem>}
            </Menu>
        </>
    );
}

export default SearchParamSelect;
