// Copyright (C) 2024, Affinda

import { Box, Stack, TableCell } from '@mui/material';
import { PropsWithChildren } from 'react';

interface TableRowControlsProps extends PropsWithChildren {
    forceDisplay?: boolean;
}

function TableRowControls({ forceDisplay, children }: TableRowControlsProps) {
    return (
        <TableCell
            padding="none"
            sx={{
                position: 'sticky',
                right: 0,
                pr: '0 !important',
                mr: 0,
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'background.paper',

                    'tr:not(:hover) &': {
                        visibility: forceDisplay === true ? undefined : 'hidden',
                    },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        left: '-20px',
                        width: '20px',
                        background:
                            'linear-gradient(to right, transparent, var(--mui-palette-background-paper))',
                    },
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        backgroundColor: 'action.hover',
                        pr: 1,
                        '& .tabler-icon': {
                            strokeWidth: 1.3,
                        },
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            left: '-20px',
                            width: '20px',
                            background:
                                'linear-gradient(to right, transparent, var(--mui-palette-action-hover))',
                        },
                    }}
                >
                    {children}
                </Stack>
            </Box>
        </TableCell>
    );
}

export default TableRowControls;
