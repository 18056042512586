import { Button, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { NoteMeta, NoteParams, notesQuery } from '../../../api/notes';
import InfoTooltip from '../../../components/InfoTooltip';
import Placeholder from '../../../components/Placeholder';
import GenericTable from '../../../components/table/genericTable';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import CreateNoteDialog from './CreateNoteDialog';
import { columns } from './NoteColumns';
import NoteDrawer from './NoteDrawer';

function Notes() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const queryOptions: NoteParams = {
        workspace: workspace.id,
    };

    const query = useQuery({
        ...notesQuery(queryOptions),
        placeholderData: (previousData, _) => previousData,
    });

    return (
        <PageLayout
            title={
                <Stack direction="row" gap={2} alignItems="baseline">
                    Notes
                    <InfoTooltip title="Notes left here are only visible to other users from your organisation." />
                    {query.isRefetching ? <CircularProgress size={20} /> : null}
                </Stack>
            }
            actions={
                <Button variant="contained" onClick={() => showModal(CreateNoteDialog)}>
                    Create Note
                </Button>
            }
        >
            <GenericTable<NoteMeta>
                StackProps={{ mt: 3 }}
                defaultSort="-created_at"
                objects={query.data?.results || []}
                columns={columns}
                placeholder={
                    <Placeholder
                        title="No Notes yet"
                        description="Create a Note to keep track of important information."
                    />
                }
                onRowClick={(note) => showModal(NoteDrawer, { note })}
            />
        </PageLayout>
    );
}

export default Notes;
