import { Box, Fade, RadioGroup, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import { deleteGenericDocumentMutation, uploadGenericDocument } from '../../../api/genericDocument';
import { queryClient } from '../../../api/queryClient';
import { components } from '../../../api/schema';
import {
    statementOfPositionTaskQuery,
    updateStatementOfPositionTask,
} from '../../../api/statementOfPosition';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import { Card } from '../../../components/Card';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import LoadingArea from '../../../components/LoadingArea';
import QueryProgress from '../../../components/QueryProgress';
import SmallRadio from '../../../components/SmallRadio';
import UploadDropArea from '../../../components/UploadDropArea';
import UploadPlaceholder from '../../../components/UploadPlaceholder';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import { useStore } from '../../../store/storeContext';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

type DistributionMethod = components['schemas']['DistributionMethodEnum'];

function StatementOfPosition() {
    const store = useStore();
    const workspace = useWorkspace();

    const sopTask = workspace.tasks.statement_of_position;
    invariant(sopTask);

    const query = useQuery(statementOfPositionTaskQuery(sopTask));

    const onUpload = (file: File) =>
        store.uploadDocument(
            file,
            (file: File) =>
                uploadGenericDocument(
                    workspace.id,
                    file,
                    'Statement of Position',
                    'statement_of_position'
                ),
            () => {
                enqueueSnackbar('Document upload successful.', {
                    variant: 'success',
                    autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                queryClient.invalidateQueries(statementOfPositionTaskQuery(sopTask));
            }
        );

    const updateMutation = useMutation(updateStatementOfPositionTask(sopTask));

    const handleDistributionMethodChange = (value: DistributionMethod) => {
        updateMutation.mutate(
            { distribution_method: value },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(statementOfPositionTaskQuery(sopTask));
                },
            }
        );
    };

    const handleMarkComplete = () => {
        updateMutation.mutate(
            { status: 'completed' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Statement of Position accepted.', {
                        variant: 'success',
                        autoHideDuration: 4000,
                    });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoComplete = () => {
        updateMutation.mutate(
            { status: 'ready_for_action' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(statementOfPositionTaskQuery(sopTask));
            },
        });
    };

    if (!query.data) return <LoadingArea />;

    return (
        <QueryProgress query={query}>
            <PageLayout
                title={'Statement of Position'}
                actions={
                    <CompleteTaskButton
                        completed={query.data?.status === 'completed'}
                        onComplete={handleMarkComplete}
                        onUndo={handleUndoComplete}
                        sx={{ pl: 2 }}
                    />
                }
            >
                <Stack gap={4} sx={{ mt: 4 }}>
                    <Card
                        disablePadding
                        title="Information sharing"
                        description="Indicate how the Statement of Position will be shared"
                    >
                        <RadioGroup
                            value={query.data.distribution_method}
                            onChange={(e) => {
                                const value = e.target.value as DistributionMethod;
                                handleDistributionMethodChange(value);
                            }}
                        >
                            <SmallRadio value="on_platform" label="Share via estateXchange" />
                            <SmallRadio
                                value="off_platform"
                                label="Send outside of estateXchange"
                            />
                        </RadioGroup>
                    </Card>
                    <Fade in={query.data.distribution_method === 'on_platform'}>
                        <Box>
                            <Card
                                disablePadding
                                title="Statement of Position documents"
                                description="Upload Statement(s) of Position and any supporting documents"
                            >
                                <Stack gap={2} sx={{ mt: 2 }}>
                                    <UploadDropArea onUpload={onUpload} multiple={true}>
                                        <UploadPlaceholder onUpload={onUpload} multiple={true} />
                                    </UploadDropArea>

                                    {!!query.data?.other_documents.length ? (
                                        <DocumentsTable
                                            documents={Array.from(
                                                query.data?.other_documents || []
                                            )}
                                            onDelete={handleDelete}
                                        />
                                    ) : null}
                                </Stack>
                            </Card>
                        </Box>
                    </Fade>
                </Stack>
            </PageLayout>
        </QueryProgress>
    );
}

export default StatementOfPosition;
