import { Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import { deceasedDetailQuery } from '../../../../api/deceasedDetail';
import {
    proofOfAuthorityTaskQuery,
    updateProofOfAuthorityTask,
} from '../../../../api/proofOfAuthority';
import { queryClient } from '../../../../api/queryClient';
import { workspaceMembershipQuery } from '../../../../api/workspaceMembership';
import CompleteTaskButton from '../../../../components/CompleteTaskButton';
import LoadingArea from '../../../../components/LoadingArea';
import Placeholder from '../../../../components/Placeholder';
import PageLayout from '../../PageLayout';
import { useWorkspace } from '../../workspaceContext';
import GrantOfRepresentationCard from './GrantOfRepresentationCard';
import LegalPersonalRepresentativesCard from './LegalPersonalRepresentativesCard';
import OtherProofOfAuthorityCard from './OtherProofOfAuthorityCard';
import WillCard from './WillCard';

function ProofOfAuthority() {
    const workspace = useWorkspace();
    const poaTask = workspace.tasks.proof_of_authority;
    invariant(poaTask);

    const { data: deceasedDetails } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));
    const updateMutation = useMutation(updateProofOfAuthorityTask(poaTask));
    const handleAccept = () => {
        updateMutation.mutate(
            {
                status: 'completed',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Proof of Authority accepted.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoAccept = () => {
        updateMutation.mutate(
            {
                status: 'ready_for_action',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const query = useQuery(proofOfAuthorityTaskQuery(poaTask));
    if (!query.data) return <LoadingArea />;

    return (
        <PageLayout
            title="Proof of Authority"
            actions={
                <CompleteTaskButton
                    completed={query.data?.status === 'completed'}
                    onComplete={handleAccept}
                    onUndo={handleUndoAccept}
                    sx={{ pl: 2 }}
                />
            }
        >
            <Stack gap={6} sx={{ mt: 4 }}>
                {deceasedDetails?.add_details === true ? (
                    <>
                        <WillCard />
                        <LegalPersonalRepresentativesCard />
                        <GrantOfRepresentationCard />
                        <OtherProofOfAuthorityCard />
                    </>
                ) : (
                    <Placeholder title="Proof of Authority detail has not been provided" />
                )}
            </Stack>
        </PageLayout>
    );
}

export default ProofOfAuthority;
