import { UseInfiniteQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type Notification = components['schemas']['Notification'];
export type NotificationType = components['schemas']['NotificationTypeEnum'];
export type NotificationData = components['schemas']['NotificationData'];
export type NotificationDataDocumentUpload =
    components['schemas']['NotificationDataDocumentUpload'];
export type NotificationDataTaskVoiCompleted =
    components['schemas']['NotificationDataEVoiCompleted'];
export type NotificationDataTaskVoiResponded =
    components['schemas']['NotificationDataInvitationResponded'];

export function notificationsQuery(
    options?: operations['notifications_list']['parameters']['query']
) {
    return {
        queryKey: ['notifications', options],
        queryFn: async () =>
            await api<components['schemas']['PaginatedNotificationList']>({
                url: '/notifications',
                params: options,
                method: 'GET',
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<components['schemas']['PaginatedNotificationList']>;
}

export function updateNotificationMutation(notificationId: string) {
    return {
        mutationFn: (data: Partial<Notification>) =>
            api<Notification>({
                url: `/notifications/${notificationId}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
        },
    } satisfies UseMutationOptions<Notification, any, Partial<Notification>, any>;
}

export function deleteNotificationMutation(notificationId: string) {
    return {
        mutationFn: () =>
            api<void>({
                url: `/notifications/${notificationId}`,
                method: 'DELETE',
            }).then((response) => response.data),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
        },
    } satisfies UseMutationOptions<void, any, any, any>;
}

export function markAllNotificationsReadMutation(workspaceId: string) {
    return {
        mutationFn: () =>
            api<void>({
                url: `/notifications/mark_all_as_read`,
                method: 'POST',
                data: {
                    workspace: workspaceId,
                },
            }).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
        },
    } satisfies UseMutationOptions<void, any, any, any>;
}
