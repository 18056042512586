import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { IconLogout } from '@tabler/icons-react';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useId } from 'react';
import { Form } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import UserAvatar from '../../components/UserAvatar';
import useUser from '../../hooks/useUser';

export function UserMenu() {
    const user = useUser();
    const popupState = usePopupState({ variant: 'popover', popupId: useId() });
    const { auth0Enabled } = appSettings;

    return (
        <>
            <Tooltip title={user?.name}>
                <IconButton sx={{ p: 0.5, color: 'white' }} {...bindTrigger(popupState)}>
                    <UserAvatar size={32} user={user} sx={{}} />
                </IconButton>
            </Tooltip>

            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Form
                    method={auth0Enabled ? 'get' : 'post'}
                    action={auth0Enabled ? '/api/auth/auth0_logout/' : '/logout'}
                    reloadDocument={auth0Enabled}
                >
                    <MenuItem component="button" type="submit" onClick={popupState.close}>
                        <ListItemIcon>
                            <IconLogout />
                        </ListItemIcon>
                        <ListItemText>Log Out</ListItemText>
                    </MenuItem>
                </Form>
            </Menu>
        </>
    );
}
