import { Button, Stack } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { relatedPeopleQuery } from '../../../../api/relatedPerson';
import Placeholder from '../../../../components/Placeholder';
import { RelatedPeopleTable } from '../../../../components/relatedPeople/RelatedPeopleTable';
import RelatedPersonDrawer from '../../../../components/relatedPeople/RelatedPersonDrawer';
import PageLayout from '../../PageLayout';
import { useWorkspace } from '../../workspaceContext';

const AssociatedPeople: FC = () => {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const navigate = useNavigate();

    const relatedPeopleQueryResult = useQuery(relatedPeopleQuery(workspace.id));
    const relatedPeople = relatedPeopleQueryResult.data?.results ?? [];

    return (
        <PageLayout
            title="Associated People"
            actions={
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                        showModal(RelatedPersonDrawer, {
                            workspace,
                            person: undefined,
                        });
                    }}
                >
                    Add Person
                </Button>
            }
        >
            <Stack gap={4} sx={{ mt: 4 }}>
                <RelatedPeopleTable
                    relatedPeople={relatedPeople}
                    rowProps={{
                        onClick: (person) => {
                            showModal(RelatedPersonDrawer, {
                                workspace,
                                person,
                            });
                        },
                    }}
                    placeholder={
                        <Placeholder
                            title="No Associated People yet"
                            description="Add people related to the estate and manage their identity documents"
                        />
                    }
                />

                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={<IconArrowRight />}
                        onClick={() => navigate(`/workspace/${workspace.id}`)}
                    >
                        Next step
                    </Button>
                </Stack>
            </Stack>
        </PageLayout>
    );
};

export default AssociatedPeople;
