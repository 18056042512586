import {
    Icon,
    IconEdit,
    IconFilePlus,
    IconMailCheck,
    IconMailX,
    IconUserScan,
} from '@tabler/icons-react';
import { Notification, NotificationType } from '../../../api/notification';

export const notificationIcon: Record<NotificationType, Icon> = {
    participant_noti_letter_uploaded: IconFilePlus,
    will_uploaded: IconFilePlus,
    other_pod_uploaded: IconFilePlus,
    other_poa_uploaded: IconFilePlus,
    gor_uploaded: IconFilePlus,
    related_person_id_doc_uploaded: IconFilePlus,
    sop_uploaded: IconFilePlus,
    finalisation_doc_uploaded: IconFilePlus,
    invite_accepted: IconMailCheck,
    invite_declined: IconMailX,
    e_voi_completed_prof_rep: IconUserScan,
    e_voi_completed_participant: IconUserScan,
    has_will_edited: IconEdit,
    will_additional_details_edited: IconEdit,
    gor_status_edited: IconEdit,
    related_person_role_edited: IconEdit,
    deceased_given_names_edited: IconEdit,
    deceased_family_name_edited: IconEdit,
    deceased_dob_edited: IconEdit,
    deceased_dod_edited: IconEdit,
    deceased_aliases_edited: IconEdit,
};

export function notificationDescription(notification: Notification): string {
    const { type, data } = notification;

    switch (type) {
        case 'participant_noti_letter_uploaded':
        case 'will_uploaded':
        case 'other_pod_uploaded':
        case 'other_poa_uploaded':
        case 'gor_uploaded':
        case 'related_person_id_doc_uploaded':
        case 'sop_uploaded':
        case 'finalisation_doc_uploaded':

        case 'e_voi_completed_prof_rep':
        case 'e_voi_completed_participant':
            if ('file_name' in data && 'document_type' in data) {
                const fileName = data.file_name as string;
                const truncatedFileName =
                    fileName.length > 30
                        ? fileName.substring(0, 27) + '...' + fileName.split('.').pop()
                        : fileName;
                return `${data.document_type} uploaded - ${truncatedFileName}`;
            }
            break;
        case 'invite_accepted':
            if ('participant_name' in data) {
                return `Invite accepted by ${data.participant_name}`;
            }
            break;
        case 'invite_declined':
            if ('participant_name' in data) {
                return `Invite declined by ${data.participant_name}`;
            }
            break;

        case 'e_voi_completed_prof_rep':
        case 'e_voi_completed_participant':
            if ('related_person_name' in data) {
                return `Verification of Identity completed for ${data.related_person_name}`;
            }
            break;
        case 'has_will_edited':
            return 'Will edited';
        case 'will_additional_details_edited':
            return 'Will additional details edited';
        case 'gor_status_edited':
            return 'GOR status edited';
        case 'related_person_role_edited':
            if ('related_person_name' in data) {
                return `Associated person role edited - ${data.related_person_name}`;
            }
            break;
        case 'deceased_given_names_edited':
            return 'Deceased given names edited';
        case 'deceased_family_name_edited':
            return 'Deceased family name edited';
        case 'deceased_dob_edited':
            return 'Deceased date of birth edited';
        case 'deceased_dod_edited':
            return 'Deceased date of death edited';
        case 'deceased_aliases_edited':
            return 'Deceased aliases edited';
    }

    return 'Unknown notification type';
}
