import {
    Button,
    FilledInput,
    IconButton,
    Link,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { IconSend, IconTrash } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { BaseSyntheticEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { FinalisationFormDocument } from '../../../api/document';
import {
    deleteFinalisationFormDocumentMutation,
    formsAndIndemnitiesTaskQuery,
    updateFormsAndIndemnitiesTask,
    uploadFinalisationFormDocument,
} from '../../../api/formsAndIndemnities';
import { queryClient } from '../../../api/queryClient';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import DateTimeString from '../../../components/DateTimeString';
import DeleteDocumentDialog from '../../../components/DeleteDocumentDialog';
import QueryProgress from '../../../components/QueryProgress';
import SmallRadio from '../../../components/SmallRadio';
import useUser from '../../../hooks/useUser';
import { useStore } from '../../../store/storeContext';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

function AccountInstructions() {
    const workspace = useWorkspace();
    const faiTask = workspace.tasks.forms_and_indemnities;
    invariant(faiTask);

    const query = useQuery(formsAndIndemnitiesTaskQuery(faiTask));

    const updateMutation = useMutation(updateFormsAndIndemnitiesTask(faiTask));

    const handleComplete = () => {
        updateMutation.mutate(
            {
                status: 'completed',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Document upload successful.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoComplete = () => {
        updateMutation.mutate(
            {
                status: 'ready_for_action',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    return (
        <QueryProgress query={query}>
            <PageLayout
                title="Account Instructions"
                actions={
                    <CompleteTaskButton
                        completed={query.data?.status === 'completed'}
                        onComplete={handleComplete}
                        onUndo={handleUndoComplete}
                        sx={{ pl: 2 }}
                    />
                }
            >
                <Stack sx={{ mt: 3 }}>
                    <FormTable forms={query.data?.finalisation_forms || null} />
                </Stack>
            </PageLayout>
        </QueryProgress>
    );
}

function FormTable({ forms }: { forms: readonly FinalisationFormDocument[] | null }) {
    return (
        <TableContainer sx={{ overflow: 'hidden' }} className="table-soft">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="55%" sx={{ pl: 3 }}>
                            File to send
                        </TableCell>
                        <TableCell>Uploaded</TableCell>
                        <TableCell>Reply</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <FormTableRow key={forms?.length} form={null} />
                    {forms?.map((form) => <FormTableRow key={form.id} form={form} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function FormTableRow({ form }: { form: FinalisationFormDocument | null }) {
    const reply = form?.reply || null;
    const store = useStore();
    const workspace = useWorkspace();
    const user = useUser();
    const hasDefaultForm = !!user?.institution_organisation?.has_default_account_instruction;

    const { showModal } = useModal();

    const faiTask = workspace.tasks.forms_and_indemnities;
    if (faiTask === null) return null;

    const formSent = form !== null;
    const formReplied = reply !== null;

    const [useDefault, setUseDefault] = useState(formSent && !!form.use_default);
    const [formFile, setFormFile] = useState<File | null>(null);

    let actionText = '';
    if (formSent) {
        actionText = `File sent ${DateTimeString(form.created_at)}`;
    }

    let completionStatus = '';
    if (formReplied) {
        completionStatus = `Reply received ${DateTimeString(reply.created_at)}`;
    } else if (formSent) {
        completionStatus = 'Waiting for reply';
    }

    const upload = () => {
        if (!useDefault && formFile === null) return;

        // Just a dummy file to satisfy typescript
        const dummyBlob = new Blob([''], { type: 'text/plain' });
        const dummyFile = new File([dummyBlob], '');

        const fileToUpload = useDefault ? dummyFile : formFile!;

        store.uploadDocument(
            fileToUpload,
            (file: File) =>
                uploadFinalisationFormDocument(
                    workspace.id,
                    useDefault,
                    useDefault ? undefined : file
                ),
            () => {
                enqueueSnackbar('Document upload successful.', {
                    variant: 'success',
                    autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                queryClient.invalidateQueries(formsAndIndemnitiesTaskQuery(faiTask));
            }
        );
    };

    const deleteMutation = useMutation(deleteFinalisationFormDocumentMutation());

    const handleDelete = () => {
        deleteMutation.mutate(form!.id, {
            onSuccess: () => {
                enqueueSnackbar('File deleted.', { variant: 'success' });
                queryClient.invalidateQueries(formsAndIndemnitiesTaskQuery(faiTask));
            },
        });
    };

    return (
        <TableRow>
            <TableCell sx={{ pl: 3 }}>
                <Stack direction="row" alignItems="center">
                    {hasDefaultForm ? (
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={useDefault}
                            onChange={(e) => setUseDefault(e.target.value === 'true')}
                            sx={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
                        >
                            <SmallRadio
                                value={true}
                                label="Use default"
                                checkboxProps={{
                                    disabled: formSent,
                                }}
                            />
                            <SmallRadio
                                value={false}
                                label="Custom"
                                checkboxProps={{
                                    disabled: formSent,
                                }}
                            />
                        </RadioGroup>
                    ) : null}

                    {!useDefault && !formSent ? (
                        <FilledInput
                            type="file"
                            size="small"
                            disableUnderline
                            hiddenLabel
                            sx={{
                                pr: 0.5,
                                borderRadius: 1,
                                flexGrow: 1,
                            }}
                            onChange={(e: BaseSyntheticEvent) => {
                                setFormFile(e.target.files[0]);
                            }}
                        />
                    ) : formSent ? (
                        <Link component={RouterLink} target="_blank" to={form.file!}>
                            {form.file_name}
                        </Link>
                    ) : null}
                </Stack>
            </TableCell>

            <TableCell>
                {!formSent && (
                    <Button
                        variant="outlined"
                        color="inherit"
                        disabled={formSent}
                        onClick={upload}
                        startIcon={<IconSend />}
                    >
                        {formSent ? 'Sent' : 'Send'}
                    </Button>
                )}
                {actionText}
            </TableCell>

            <TableCell>
                <Stack gap={0.2} alignItems="flex-start">
                    <span>{completionStatus}</span>
                    {formReplied && (
                        <Link component={RouterLink} target="_blank" to={reply.file}>
                            View Reply
                        </Link>
                    )}
                </Stack>
            </TableCell>

            <TableCell
                padding="none"
                sx={{
                    width: 'fit-content',
                    position: 'sticky',
                    right: 0,
                    mr: 1,
                }}
            >
                {formSent && !reply ? (
                    <IconButton
                        onClick={() =>
                            showModal(DeleteDocumentDialog, {
                                onDelete: handleDelete,
                            })
                        }
                    >
                        <IconTrash />
                    </IconButton>
                ) : null}
            </TableCell>
        </TableRow>
    );
}

export default AccountInstructions;
