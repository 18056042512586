import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface FileLinkProps {
    file_name: string;
    file: string;
    onDelete?: () => void;
}

function FileLink({ file_name, file, onDelete }: FileLinkProps) {
    const [hover, setHover] = useState(false);

    return (
        <Stack
            direction="row"
            gap={1}
            alignItems="center"
            component={RouterLink}
            target="_blank"
            to={file || ''}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                color: 'inherit',
                maxWidth: '20ch',
                '&:hover': { textDecoration: 'underline' },
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    flexShrink: 1,
                    textDecoration: 'none',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                {file_name}
            </Typography>

            {onDelete ? (
                <Tooltip title="Delete response">
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            onDelete();
                        }}
                        sx={{ visibility: hover ? 'visible' : 'hidden' }}
                    >
                        <IconTrash size={22} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Stack>
    );
}

export default FileLink;
