import {
    Box,
    CircularProgress,
    Stack,
    StackProps,
    SxProps,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Theme,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import useSort from '../../hooks/useSort';
import SearchInput from '../SearchInput';
import TableSortLabel from '../TableSortLabel';
import GenericTableRow from './genericTableRow';

export interface CellComponentProps<T> {
    object: T;
    column: Column<T>;
}

export interface Column<T> {
    id: string;
    label?: string;
    Component: React.ComponentType<CellComponentProps<T>>;
    sortable?: string;
    sx?: SxProps<Theme>;
}

export interface TableTab {
    title: string;
    value: string;
    icon?: React.ReactElement;
}

interface TableProps<T> {
    defaultSort: string;
    objects: T[];
    columns: Column<T>[];
    placeholder?: React.ReactNode;
    tabs?: TableTab[];
    currentTab?: string;
    actions?: React.ReactNode;
    enableSearch?: boolean;
    showLoadSpinner?: boolean;
    onRowClick?: (object: T) => void;
    StackProps?: StackProps;
    rowSx?: (object: T) => SxProps<Theme>;
}

function GenericTable<T>({
    defaultSort,
    objects,
    columns,
    placeholder,
    tabs,
    currentTab,
    enableSearch,
    actions,
    showLoadSpinner,
    onRowClick,
    StackProps,
    rowSx,
}: TableProps<T>) {
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';

    const { sortColumn, sortDirection, sortLink } = useSort(defaultSort);
    const isActive = (column: Column<T>) => sortColumn === column.sortable;

    function tabLink(tab: string) {
        const params = new URLSearchParams(searchParams);
        tab ? params.set('tab', tab) : params.delete('tab');
        return `?${params}`;
    }

    return (
        <Stack flexGrow={1} {...StackProps}>
            <Stack direction="row" gap={1} alignItems="center">
                {tabs ? (
                    <Tabs className="underlined" value={currentTab}>
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.value}
                                value={tab.value}
                                label={tab.title}
                                icon={tab.icon}
                                iconPosition="end"
                                className="underlined"
                                component={RouterLink}
                                to={tabLink(tab.value)}
                            />
                        ))}
                    </Tabs>
                ) : null}

                <Box flexGrow={1} />

                {showLoadSpinner ? <CircularProgress size={20} /> : null}

                {actions ? (
                    <Stack direction="row" gap={1} alignItems="center" sx={{ py: 1 }}>
                        {actions}
                    </Stack>
                ) : null}

                {enableSearch ? (
                    <SearchInput
                        value={search}
                        onUpdate={(value) => setSearchParams({ search: value })}
                    />
                ) : null}
            </Stack>

            <TableContainer
                sx={{
                    height: '100%',
                    overflowX: 'initial',
                }}
            >
                <Table stickyHeader className="table-soft">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} sx={column.sx}>
                                    {column.sortable ? (
                                        <TableSortLabel
                                            label={column.label}
                                            sortLink={sortLink(column.sortable)}
                                            sortDirection={sortDirection}
                                            isActive={isActive(column)}
                                        />
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {objects.map((object, index) => (
                            <GenericTableRow<T>
                                key={index}
                                columns={columns}
                                object={object}
                                onClick={onRowClick}
                                rowSx={rowSx}
                            />
                        ))}
                    </TableBody>
                </Table>
                {objects.length === 0 ? <Stack height="100%">{placeholder}</Stack> : null}
            </TableContainer>
        </Stack>
    );
}

export default GenericTable;
