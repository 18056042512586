import { Box, Button, CircularProgress, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { queryClient } from '../../api/queryClient';
import { WorkspaceMeta, WorkspacesRequest, workspacesQuery } from '../../api/workspace';
import Breadcrumbs from '../../components/Breadcrumbs';
import CopyrightFooter from '../../components/CopyrightFooter';
import LoadingArea from '../../components/LoadingArea';
import Placeholder from '../../components/Placeholder';
import SearchInput from '../../components/SearchInput';
import UpdateBadge from '../../components/UpdateBadge';
import WorkspacesTable from '../../components/workspacesTable/WorkspacesTable';
import useSort from '../../hooks/useSort';
import useUser from '../../hooks/useUser';
import CreateWorkspaceDialog from './CreateWorkspaceDialog';

export enum WorkspacesState {
    All = 'all',
    Active = 'active',
    Draft = 'draft',
    InProgress = 'in_progress',
    Invited = 'invited',
    Declined = 'declined',
    Closed = 'closed',
}

interface tab {
    label: ReactNode;
    value: WorkspacesState;
}

function Workspaces() {
    const user = useUser();
    const isWorkspaceOwner = user?.de_rep_organisation !== null;

    const { showModal } = useModal();

    const [searchParams, setSearchParams] = useSearchParams();
    const state: any = searchParams.get('state') || WorkspacesState.Active;

    const defaultSort = '-all_action_required_items_count';
    const { sortParam } = useSort(defaultSort);

    const search = searchParams.get('search') || '';

    function stateLink(state?: string) {
        const params = new URLSearchParams(searchParams);
        state ? params.set('state', state) : params.delete('state');
        return `?${params}`;
    }

    const workspaceQueryOptions: WorkspacesRequest = {
        ...(isWorkspaceOwner
            ? {
                  status:
                      state === WorkspacesState.All
                          ? undefined
                          : state === WorkspacesState.Active
                            ? ['draft', 'in_progress']
                            : [state],
              }
            : {
                  membership_status:
                      state === WorkspacesState.All
                          ? undefined
                          : state === WorkspacesState.Active
                            ? ['invited', 'in_progress']
                            : [state],
              }),
        ordering: sortParam,
        search: search,
    };

    const query = useInfiniteQuery(
        {
            ...workspacesQuery(workspaceQueryOptions),
            placeholderData: (previousData, previousQuery) => previousData,
        },
        queryClient
    );

    const invitedCountQuery = useInfiniteQuery(
        workspacesQuery({
            membership_status: ['invited'],
        })
    );

    const results = (query.data?.pages.flatMap((page) => page.results) || []) as WorkspaceMeta[];

    const tabs: tab[] = [
        {
            label: 'Active',
            value: WorkspacesState.Active,
        },
        {
            label: 'All',
            value: WorkspacesState.All,
        },
    ];

    if (isWorkspaceOwner) {
        tabs.push(
            {
                label: 'Draft',
                value: WorkspacesState.Draft,
            },
            {
                label: 'In progress',
                value: WorkspacesState.InProgress,
            }
        );
    } else {
        tabs.push(
            {
                label: (
                    <Stack direction="row" gap={0.5} alignItems="center">
                        Invited
                        <UpdateBadge
                            count={
                                invitedCountQuery.data?.pages.flatMap((page) => page.results)
                                    .length || 0
                            }
                            updateColor="primary"
                        />
                    </Stack>
                ),
                value: WorkspacesState.Invited,
            },
            {
                label: 'Declined',
                value: WorkspacesState.Declined,
            }
        );
    }

    tabs.push({
        label: 'Closed',
        value: WorkspacesState.Closed,
    });

    return (
        <Stack
            sx={{
                pt: 3,
                pr: 3,
                flexGrow: 1,
                backgroundColor: 'background.default',
                overflowX: 'hidden',
            }}
        >
            <Helmet>Workspaces</Helmet>

            <Breadcrumbs>
                <Typography key="2" color="text.primary">
                    Workspaces
                </Typography>
            </Breadcrumbs>

            <Stack direction="row" alignItems="center" sx={{ pb: 2 }}>
                <Typography variant="h3">Workspaces</Typography>
                <Tabs value={state} sx={{ alignItems: 'center', mt: 1, ml: 2 }}>
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                            component={RouterLink}
                            to={stateLink(tab.value)}
                            state={{ keepPreviousData: 'false' }}
                        />
                    ))}
                </Tabs>

                <SearchInput
                    value={search}
                    onUpdate={(q) => {
                        const params = new URLSearchParams(searchParams);
                        q ? params.set('search', q) : params.delete('search');
                        setSearchParams(params);
                    }}
                    sx={{ mt: 1, color: 'text.secondary' }}
                />

                <Stack direction="row" gap={2} alignItems={'center'}>
                    {query.isRefetching ? <CircularProgress size={20} /> : null}
                </Stack>

                <Box sx={{ flexGrow: 1 }} />

                {isWorkspaceOwner ? (
                    <Button variant="contained" onClick={() => showModal(CreateWorkspaceDialog)}>
                        Create Workspace
                    </Button>
                ) : null}
            </Stack>
            {!query.data ? (
                <LoadingArea />
            ) : (
                <Paper
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        overflow: 'auto',
                        borderRadius: 5,
                    }}
                >
                    {results.length ? (
                        <WorkspacesTable defaultSort={defaultSort} workspaces={results} />
                    ) : (
                        <Placeholder title={search ? 'No Workspaces found' : 'No Workspaces yet'} />
                    )}
                </Paper>
            )}
            <CopyrightFooter />
        </Stack>
    );
}

export default Workspaces;
