import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { DatePickerElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { deceasedDetailQuery, updateDeceasedDetail } from '../../../api/deceasedDetail';
import { dateString } from '../../../api/utils';
import { Card } from '../../../components/Card';
import FormGrid from '../../../components/FormGrid';
import InfoTooltip from '../../../components/InfoTooltip';
import QueryProgress from '../../../components/QueryProgress';
import AddressField from '../../../components/fields/AddressField';
import AddressFieldArray from '../../../components/fields/AddressFieldArray';
import TextFieldArray from '../../../components/fields/TextFieldArray';
import {
    characterLimit,
    noNumbers,
    noSpecialChars,
    optionalString,
    phoneNumber,
    requiredDate,
    requiredString,
} from '../../../validationRules';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

export enum tabs {
    DeceasedDetails = 'deceased-details',
    KnownAssets = 'known-assets',
    EstateRepresentativeDetails = 'estate-representative-details',
    BankDetails = 'bank-details',
}

function DeceasedInformation() {
    return (
        <PageLayout title="Deceased Information">
            <Stack gap={0}>
                <DeceasedInformationContent />
            </Stack>
        </PageLayout>
    );
}

const addressValidationSchema = z.object({
    street: requiredString,
    city: requiredString,
    state: requiredString,
    postcode: requiredString,
});

function DeceasedInformationContent() {
    const workspace = useWorkspace();
    const navigate = useNavigate();
    const detailsQuery = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));
    const details = detailsQuery.data;

    const updateMutation = useMutation(updateDeceasedDetail());

    const validationSchema = z
        .object({
            family_name: requiredString.and(characterLimit(40)).and(noSpecialChars).and(noNumbers),
            given_names: requiredString.and(characterLimit(70)).and(noSpecialChars).and(noNumbers),
            aliases: z.array(
                requiredString.and(characterLimit(100)).and(noSpecialChars).and(noNumbers)
            ),
            date_of_birth: requiredDate,
            last_known_address: addressValidationSchema,
            other_addresses: z.array(addressValidationSchema),
            other_info: optionalString.and(characterLimit(300)).and(noSpecialChars),
            phone_number: optionalString.and(characterLimit(20)).and(phoneNumber),
        })
        .refine(
            // Date of birth must be before date of death if date of death is provided
            (fields) =>
                !details?.date_of_death || fields.date_of_birth < parseISO(details.date_of_death),

            {
                message: 'Date of birth must be before date of death',
                path: ['date_of_birth'],
            }
        );

    const onSubmit = (values: any) => {
        updateMutation.mutate(
            {
                id: details?.id,
                family_name: values.family_name,
                given_names: values.given_names,
                date_of_birth: dateString(values.date_of_birth),
                aliases: values.aliases,
                other_info: values.other_info,
                residential_addresses: {
                    current: values.last_known_address,
                    others: values.other_addresses,
                },
                phone_number: values.phone_number,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Changes saved.', { variant: 'success' });
                    navigate(`/workspace/${workspace.id}/pod-data-entry`);
                },
            }
        );
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            family_name: details?.family_name || '',
            given_names: details?.given_names || '',
            aliases: details?.aliases || [],
            date_of_birth: details?.date_of_birth ? parseISO(details?.date_of_birth) : null,
            last_known_address: details?.residential_addresses.current || {
                street: '',
                city: '',
                state: '',
                postcode: '',
            },
            other_addresses: details?.residential_addresses.others || [],
            other_info: details?.other_info || '',
            phone_number: details?.phone_number || '',
        },
        resolver: zodResolver(validationSchema),
    });

    const { isDirty } = formMethods.formState;

    return (
        <QueryProgress query={detailsQuery}>
            <FormContainer formContext={formMethods} onSuccess={onSubmit}>
                <Stack gap={2} mt={4}>
                    <Card title="">
                        <FormGrid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    fullWidth
                                    required
                                    label="Family name"
                                    name="family_name"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldElement
                                    fullWidth
                                    required
                                    label="Given names"
                                    name="given_names"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldArray
                                    name="aliases"
                                    label={
                                        <Stack direction="row" gap={1} alignItems="center">
                                            Other known aliases
                                            <InfoTooltip title="Include here any other names the deceased may have been known by." />
                                        </Stack>
                                    }
                                    itemLabel={() => `Alias`}
                                    addMessage="Add an alias"
                                    sx={{ width: '50%' }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerElement
                                    name="date_of_birth"
                                    label="Date of birth"
                                    required
                                    maxDate={new Date()}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </FormGrid>
                    </Card>

                    <Card title="Addresses">
                        <Stack gap={1}>
                            <Typography>Last known residential address</Typography>
                            <AddressField name="last_known_address" required />
                        </Stack>
                        <AddressFieldArray
                            name="other_addresses"
                            label={
                                <Stack direction="row" gap={1} alignItems="center">
                                    Other known addresses
                                    <InfoTooltip title="Include here any other addresses the deceased may have lived at in the last two years." />
                                </Stack>
                            }
                            gap={2}
                        />
                        <TextFieldElement
                            fullWidth
                            sx={{ maxWidth: '50%' }}
                            label="Phone number"
                            name="phone_number"
                        />
                    </Card>

                    <Card title="Other">
                        <FormGrid>
                            <Grid item xs={12}>
                                <TextFieldElement
                                    fullWidth
                                    multiline
                                    label="Other useful identifying information"
                                    name="other_info"
                                />
                            </Grid>
                        </FormGrid>
                    </Card>

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{
                            bottom: 0,
                            zIndex: 3,
                        }}
                    >
                        <Stack direction="row" width="fit-content">
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                size="large"
                                loading={updateMutation.status === 'pending'}
                                startIcon={<IconArrowRight />}
                            >
                                Next step
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
            </FormContainer>
        </QueryProgress>
    );
}

export default DeceasedInformation;
