import { SxProps, Theme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { createElement } from 'react';
import { Column } from './genericTable';

interface TableRowProps<T> {
    columns: Column<T>[];
    object: T;
    onClick?: (object: T) => void;
    rowSx?: (object: T) => SxProps<Theme>;
}

function GenericTableRow<T>({ columns, object, onClick, rowSx }: TableRowProps<T>) {
    return (
        <TableRow
            hover={onClick !== undefined}
            onClick={onClick ? () => onClick(object) : undefined}
            sx={{
                textDecoration: 'none',
                cursor: onClick ? 'pointer' : 'default',
                ...rowSx?.(object),
            }}
        >
            {columns.map((column) =>
                createElement(column.Component, {
                    object,
                    column,
                    key: column.id,
                })
            )}
        </TableRow>
    );
}

export default GenericTableRow;
