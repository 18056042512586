import { alpha, Button, Stack, TableCell, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { useSearchParams } from 'react-router-dom';
import { RequestMeta, RequestParams, requestsQuery } from '../../../api/requests';
import { User } from '../../../api/user';
import DateTimeString from '../../../components/DateTimeString';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import SearchParamSelect, { SearchParamSelectOption } from '../../../components/SearchParamSelect';
import GenericTable, { Column, TableTab } from '../../../components/table/genericTable';
import useUser from '../../../hooks/useUser';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import { ThemesIssuesMap } from './';
import RequestDrawer from './RequestDrawer';
import RequestStatus from './RequestStatus';
import SendRequestDialog from './SendRequestDialog';

const columns: Column<RequestMeta>[] = [
    {
        id: 'description',
        label: 'Description',
        sortable: 'description',
        Component: ({ object: request }) => (
            <TableCell>
                <Stack>
                    <Typography variant="body1">{request.theme_label}</Typography>
                    <Typography variant="body3">{request.issue}</Typography>
                </Stack>
            </TableCell>
        ),
    },
    {
        id: 'sender',
        label: 'Raised by',
        sortable: 'sender_organisation_name',
        Component: ({ object: request }) => (
            <TableCell>{request.sender_organisation_name}</TableCell>
        ),
    },
    {
        id: 'recipient',
        label: 'Recipient',
        sortable: 'recipient_organisation_name',
        Component: ({ object: request }) => (
            <TableCell>{request.recipient_organisation_name}</TableCell>
        ),
    },
    {
        id: 'updated_at',
        label: 'Last updated',
        sortable: 'updated_at',
        Component: ({ object: request }) => (
            <TableCell>{DateTimeString(request.updated_at)}</TableCell>
        ),
    },
    {
        id: 'status',
        label: 'Next action',
        Component: ({ object: request }) => (
            <TableCell>
                <RequestStatus request={request} />
            </TableCell>
        ),
    },
];

type RequestsProps = {
    issues: ThemesIssuesMap;
    userType: Exclude<User['user_type'], 'admin'>;
};

function Requests({ issues }: RequestsProps) {
    const [searchParams] = useSearchParams();
    const workspace = useWorkspace();
    const user = useUser();
    const { showModal } = useModal();
    const theme = useTheme();

    const tab = searchParams.get('tab') || 'open';
    const organisation = searchParams.get('organisation') || undefined;

    const queryOptions: RequestParams = {
        organisation: organisation,
        workspace: workspace.id,
        is_active: tab === 'open' ? true : false,
        search: searchParams.get('search') || '',
        ordering: searchParams.get('sort') || '-updated_at',
    };

    const query = useQuery({
        ...requestsQuery(queryOptions),
        placeholderData: (previousData, _) => previousData,
    });

    const organisations: SearchParamSelectOption[] = Array.from(
        new Map(
            (query.data?.results || []).flatMap((result) => [
                [
                    result.sender,
                    {
                        id: result.sender,
                        label: result.sender_organisation_name,
                    },
                ],
                [
                    result.recipient,
                    {
                        id: result.recipient,
                        label: result.recipient_organisation_name,
                    },
                ],
            ])
        ).values()
    );

    const tabs: TableTab[] = [
        { title: 'Open', value: 'open' },
        { title: 'Completed', value: 'resolved' },
    ];

    return (
        <PageLayout
            title="Requests"
            actions={
                <Button
                    variant="contained"
                    onClick={() =>
                        showModal(SendRequestDialog, {
                            sentIssues: issues,
                        })
                    }
                >
                    Create Request
                </Button>
            }
        >
            <QueryProgress query={query}>
                <GenericTable<RequestMeta>
                    StackProps={{ mt: 1 }}
                    defaultSort="-updated_at"
                    objects={query.data?.results || []}
                    columns={columns}
                    placeholder={
                        <Placeholder
                            title="No Requests yet"
                            description={`Request additional information from ${
                                user?.user_type === 'de_representative'
                                    ? 'Workspace Participants'
                                    : 'the Professional Representative'
                            }`}
                        />
                    }
                    tabs={tabs}
                    currentTab={tab}
                    showLoadSpinner={query.isRefetching}
                    enableSearch
                    actions={
                        <SearchParamSelect
                            placeholder="Filter by organisation"
                            options={organisations}
                            parameter="organisation"
                        />
                    }
                    onRowClick={(request) => showModal(RequestDrawer, { request })}
                    rowSx={(request) => ({
                        backgroundColor: request.action_required
                            ? alpha(theme.palette.secondary.light, 0.2)
                            : 'inherit',
                        '&:hover': {
                            backgroundColor: request.action_required
                                ? 'secondary.light !important'
                                : 'background.paper',
                        },
                    })}
                />
            </QueryProgress>
        </PageLayout>
    );
}

export default Requests;
