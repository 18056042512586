import { List, ListItemIcon, ListItemText, ListSubheader, Stack, Tooltip } from '@mui/material';
import { IconCheck, IconCircleDashed } from '@tabler/icons-react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { notificationsQuery } from '../../api/notification';
import { workspaceMembershipQuery, workspaceMembershipsQuery } from '../../api/workspaceMembership';
import CountBadge from '../../components/CountBadge';
import LoadingArea from '../../components/LoadingArea';
import { NavListItem } from '../../components/NavListItem';
import useUser from '../../hooks/useUser';
import { useWorkspace } from './workspaceContext';

function Sidebar() {
    const user = useUser();
    const isWorkspaceOwner = user?.de_rep_organisation !== null;

    return (
        <Stack
            component="nav"
            sx={{
                backgroundColor: 'background.default',
                overflow: 'hidden',
                height: 'fit-content',
                minWidth: 'fit-content',
                top: 75,
                bottom: 0,
                position: 'sticky',
            }}
        >
            {isWorkspaceOwner ? (
                <WorkspaceOwnerSidebarContent />
            ) : (
                <WorkspaceMemberSidebarContent />
            )}
        </Stack>
    );
}

function WorkspaceOwnerSidebarContent() {
    const workspace = useWorkspace();

    const membersQuery = useQuery({
        ...workspaceMembershipsQuery({
            workspace: workspace.id,
        }),
        placeholderData: (previousData, previousQuery) => previousData,
    });

    // TODO: add notification count to workspace detail
    const notificationQuery = useInfiniteQuery(
        notificationsQuery({
            workspace: workspace.id,
            is_read: 'false',
        })
    );

    const notifications = notificationQuery.data?.pages.flatMap((page) => page.results) || [];

    const showRequests =
        workspace.is_data_entry_complete &&
        membersQuery.data?.find((member) => member.status === 'in_progress');

    return (
        <Stack direction="column" justifyContent="space-between">
            <List>
                <ListSubheader component="div" disableSticky sx={{ pt: 0 }}>
                    Overview
                </ListSubheader>
                <NavListItem to="" end>
                    <ListItemText>Summary</ListItemText>
                </NavListItem>

                <NavListItem to="updates">
                    <ListItemText>Updates</ListItemText>
                    <CountBadge count={notifications.length} />
                </NavListItem>

                <NavListItem to="notes" end>
                    <ListItemText>Notes</ListItemText>
                </NavListItem>

                {showRequests ? (
                    <NavListItem to="requests">
                        <ListItemText>Requests</ListItemText>
                        <CountBadge count={workspace.action_required_requests_count} />
                    </NavListItem>
                ) : null}

                <ListSubheader component="div" disableSticky>
                    Estate Details
                </ListSubheader>
                <NavListItem to="deceased-information">
                    <ListItemText>Deceased Info</ListItemText>
                </NavListItem>
                <NavListItem to="pod-data-entry">
                    <ListItemText>Proof of Death</ListItemText>
                </NavListItem>
                <NavListItem to="poa-data-entry">
                    <ListItemText>Proof of Authority</ListItemText>
                </NavListItem>

                <NavListItem to="associated-people">
                    <ListItemText>Associated People</ListItemText>
                    <CountBadge count={workspace.action_required_e_voi_count} />
                </NavListItem>

                <ListSubheader component="div" disableSticky>
                    Instructions & Indemnities
                </ListSubheader>
                <NavListItem to="account-instructions">
                    <ListItemText>Account Instructions</ListItemText>
                    <CountBadge count={workspace.action_required_account_instructions_count} />
                </NavListItem>

                <ListSubheader component="div" disableSticky>
                    Correspondence
                </ListSubheader>
                <NavListItem to="statement-of-position">
                    <ListItemText>Statement of Position</ListItemText>
                </NavListItem>
                <NavListItem to="finalisation">
                    <ListItemText>Account Finalisation</ListItemText>
                </NavListItem>
            </List>
        </Stack>
    );
}

function WorkspaceMemberSidebarContent() {
    const workspace = useWorkspace();
    const membershipId = workspace.memberships[0];
    const membershipQuery = useQuery(workspaceMembershipQuery(membershipId));

    // TODO: add notification count to workspace detail
    const notificationQuery = useInfiniteQuery(
        notificationsQuery({
            workspace: workspace.id,
            is_read: 'false',
        })
    );

    const notifications = notificationQuery.data?.pages.flatMap((page) => page.results) || [];

    return (
        <Stack direction="column" spacing={10} justifyContent="space-between">
            <List>
                <ListSubheader component="div" disableSticky sx={{ pt: 0 }}>
                    Overview
                </ListSubheader>
                <NavListItem to="" end>
                    <ListItemText>Estate Details</ListItemText>
                </NavListItem>
                <NavListItem to="estate-representative" end>
                    <ListItemText>Professional Representative</ListItemText>
                </NavListItem>

                {membershipQuery.isLoading ? (
                    <LoadingArea />
                ) : membershipQuery.data?.status !== 'invited' &&
                  membershipQuery.data?.status !== 'declined' ? (
                    <>
                        <NavListItem to="updates">
                            <ListItemText>Updates</ListItemText>
                            <CountBadge count={notifications.length} />
                        </NavListItem>
                        <NavListItem to="notes" end>
                            <ListItemText>Notes</ListItemText>
                        </NavListItem>
                        <NavListItem to="requests">
                            <ListItemText>Requests</ListItemText>
                            <CountBadge count={workspace.action_required_requests_count} />
                        </NavListItem>
                        <ListSubheader component="div" disableSticky>
                            Proof Points
                        </ListSubheader>
                        <NavListItem to="pod">
                            <TaskSidebarIcon
                                checked={
                                    membershipQuery.data?.proof_of_death_task?.status ===
                                    'completed'
                                }
                            />
                            <ListItemText>Proof of Death</ListItemText>
                        </NavListItem>
                        <NavListItem to="poa">
                            <TaskSidebarIcon
                                checked={
                                    membershipQuery.data?.proof_of_authority_task?.status ===
                                    'completed'
                                }
                            />
                            <ListItemText>Proof of Authority</ListItemText>
                        </NavListItem>
                        <NavListItem to="poi">
                            <TaskSidebarIcon
                                checked={
                                    membershipQuery.data?.proof_of_identity_task?.status ===
                                    'completed'
                                }
                            />
                            <ListItemText>Proof of Identity</ListItemText>
                        </NavListItem>

                        <ListSubheader component="div" disableSticky>
                            Instructions & Indemnities
                        </ListSubheader>
                        <NavListItem to="account-instructions">
                            <TaskSidebarIcon
                                checked={
                                    membershipQuery.data?.forms_and_indemnities_task?.status ===
                                    'completed'
                                }
                            />
                            <ListItemText>Account Instructions</ListItemText>
                        </NavListItem>

                        <ListSubheader component="div" disableSticky>
                            Correspondence
                        </ListSubheader>
                        <NavListItem to="position">
                            <TaskSidebarIcon
                                checked={
                                    membershipQuery.data?.statement_of_position_task?.status ===
                                    'completed'
                                }
                            />
                            <ListItemText>Statement of Position</ListItemText>
                        </NavListItem>
                        <NavListItem to="finalisation">
                            <TaskSidebarIcon
                                checked={
                                    membershipQuery.data?.estate_finalisation_task?.status ===
                                    'completed'
                                }
                            />
                            <ListItemText>Account Finalisation</ListItemText>
                        </NavListItem>
                    </>
                ) : null}
            </List>
        </Stack>
    );
}

function TaskSidebarIcon({ checked }: { checked: boolean }) {
    return (
        <Tooltip title={checked ? 'Complete' : 'Ready for Action'}>
            <ListItemIcon>
                {checked ? <IconCheck size={20} /> : <IconCircleDashed size={20} />}
            </ListItemIcon>
        </Tooltip>
    );
}

export default Sidebar;
